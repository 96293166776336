import { createSlice } from '@reduxjs/toolkit';
import {
  request,
  generateCancelToken,
  cancelRequests,
  isCancel,
  ejectCancelInterceptor,
} from 'modules/Api/HttpClient';
import {
  LICENSE_HISTORY_LIST_URL,
  LICENSE_HISTORY_BY_ID_URL,
  BASE_TESTS_URL,
  CUSTOM_TESTS_URL,
} from 'modules/Api/Routes';
import {
  getISOStringFromStringDate,
  ConvertPlacementToProgressCheck,
} from 'modules/Utils';

let cancelToken;

const initialState = {
  loading: false,
  error: null,
  data: {
    page: 1,
    perPage: 10,
    search: '',
    test_tag: null,
    test_id: null,
    custom_test_id: null,
    startDate: null,
    endDate: null,
    sort: {
      sortType: null,
      sortBy: null,
    },
  },
  licenseHistoryId: {
    data: null,
    loading: false,
    history_licenses: [],
    error: null,
  },
  tests: {
    data: [],
    loading: false,
  },
  customTests: {
    data: [],
    loading: false,
  },
};

const LicenseHistorySlice = createSlice({
  name: 'licenseHistory',
  initialState,
  reducers: {
    cancelRequests: () => {
      cancelToken?.cancel();
      cancelRequests();
    },
    cleanState: () => ({ ...initialState }),
    /**
     * indicate that a request is started
     */
    changeSort: (state, action) => {
      const newSort = action.payload;
      state.data.sort.sortType =
        state.data.sort.sortBy === newSort && state.data.sort.sortType === 'ASC'
          ? 'DESC'
          : 'ASC';
      state.data.sort.sortBy = action.payload;
    },
    requestLicenseHistory: (state) => {
      state.loading = true;
      state.error = null;
    },
    requestLicenseHistoryId: (state, action) => {
      state.licenseHistoryId.loading = true;
      state.licenseHistoryId.data = action.payload;
      state.licenseHistoryId.history_licenses = [];
    },
    changePerPage: (state, action) => {
      state.data.perPage = action.payload;
      state.data.page = 1;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    /**
     * receive a success response
     */
    receiveRequestSuccess: (state) => {
      state.loading = false;
    },
    receiveLicenseHistoryList: (state, action) => {
      state.loading = false;
      state.data = {
        ...state.data,
        licenseHistory: action.payload.history_licenses,
        total: action.payload.total_items,
      };
    },
    receiveLicenseHistoryId: (state, action) => {
      state.licenseHistoryId.loading = false;
      state.licenseHistoryId.history_licenses = action.payload.history_licenses;
    },
    requestBaseTests: (state) => {
      state.tests = {
        ...state.tests,
        loading: true,
      };
    },
    receiveBaseTestsList: (state, action) => {
      state.tests = {
        ...state.tests,
        loading: false,
        data: ConvertPlacementToProgressCheck(action.payload.content?.tests),
      };
    },
    receiveBaseTestsError: (state, action) => {
      state.tests = {
        ...state.tests,
        loading: false,
      };
      state.error = action.payload;
    },
    requestCustomTests: (state) => {
      state.customTests = {
        ...state.customTests,
        loading: true,
      };
    },
    receiveCustomTestsList: (state, action) => {
      state.customTests = {
        ...state.customTests,
        loading: false,
        data: action.payload.content?.custom_tests,
      };
    },
    receiveCustomTestsError: (state, action) => {
      state.customTests = {
        ...state.customTests,
        loading: false,
      };
      state.error = action.payload;
    },
    clearLicenseHistoryList: (state) => {
      state.loading = false;
      state.data = {
        page: 1,
        perPage: 10,
        search: '',
        sort: {
          sortType: null,
          sortBy: null,
        },
      };
    },
    /**
     * receive an error response
     */
    receiveLicenseHistoryError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    receiveLicenseHistoryIdError: (state, action) => {
      state.licenseHistoryId.loading = false;
      state.licenseHistoryId.history_licenses = [];
      state.licenseHistoryId.error = action.payload;
    },
    changeLicenseHistoryPage: (state, action) => {
      state.data.page = action.payload;
    },
    changeLicenseHistorySearch: (state, action) => {
      state.data.search = action.payload;
    },
    changeLicenseHistoryFilters: (state, action) => {
      state.data.search = action.payload.search;
      state.data.page = isNaN(action?.payload?.page)
        ? 1
        : Number(action?.payload?.page);
      state.data.perPage = isNaN(action?.payload?.paginates_per)
        ? state.data.perPage
        : Number(action?.payload?.paginates_per);
      state.data.test_tag = action.payload?.test_tag || null;
      state.data.test_id = action.payload?.test_id || null;
      state.data.custom_test_id = action.payload?.custom_test_id || null;
      state.data.startDate = action.payload?.start_date || null;
      state.data.endDate = action.payload?.end_date || null;
    },
    clearLicenseHistoryFilters: (state) => {
      state.data.test_tag = null;
      state.data.test_id = null;
      state.data.custom_test_id = null;
      state.data.startDate = null;
      state.data.endDate = null;
      state.data.search = '';
      state.data.page = 1;
      state.data.perPage = 10;
      state.data.sort.sortType = null;
      state.data.sort.sortBy = null;
    },
  },
});

const Actions = LicenseHistorySlice.actions;

const Selectors = {
  fetchListData: (state) => state.licenseHistory,
  licenseHistoryLoading: ({ licenseHistory: { loading } }) => ({ loading }),
};

const Async = {
  fetchLicenseHistoryList: () => async (dispatch, getState) => {
    const {
      licenseHistory: {
        data: {
          page,
          perPage,
          search,
          test_id,
          custom_test_id,
          startDate,
          endDate,
          sort: { sortType, sortBy },
        },
      },
    } = getState();

    ejectCancelInterceptor();
    cancelToken?.cancel();
    cancelToken = generateCancelToken();

    let action;

    dispatch(Actions.requestLicenseHistory());

    try {
      const response = await request({
        cancelToken: cancelToken.token,
        method: 'GET',
        url: LICENSE_HISTORY_LIST_URL,
        params: {
          page,
          paginates_per: perPage,
          search,
          test_id,
          custom_test_id,
          start_date: startDate ? getISOStringFromStringDate(startDate) : null,
          end_date: endDate ? getISOStringFromStringDate(endDate) : null,
          sort: sortType,
          sort_by: sortBy,
        },
      });

      action = Actions.receiveLicenseHistoryList(response.data.content);
    } catch (e) {
      if (!isCancel(e)) {
        action = Actions.receiveLicenseHistoryError(e.message);
      }
    }

    action && dispatch(action);
  },

  fetchLicenseHistoryId: (license) => async (dispatch) => {
    let action;

    dispatch(Actions.requestLicenseHistoryId(license));

    try {
      const response = await request({
        method: 'GET',
        url: LICENSE_HISTORY_BY_ID_URL,
        params: {
          license_id: license?.id,
        },
      });

      action = Actions.receiveLicenseHistoryId(response.data.content);
    } catch (e) {
      action = Actions.receiveLicenseHistoryIdError(e.message);
    }

    action && dispatch(action);
  },

  fetchBaseTestsList: () => async (dispatch) => {
    let action;

    dispatch(Actions.requestBaseTests());

    try {
      const response = await request({
        method: 'GET',
        url: BASE_TESTS_URL,
        params: {
          show_all_tests: true,
          sort: 'ASC',
          sort_by: 'name',
        },
      });

      action = Actions.receiveBaseTestsList(response.data);
    } catch (e) {
      if (!isCancel(e)) {
        action = Actions.receiveBaseTestsError(e.message);
      }
    }

    action && dispatch(action);
  },

  fetchCustomTestsList: () => async (dispatch) => {
    let action;

    dispatch(Actions.requestCustomTests());

    try {
      const response = await request({
        method: 'GET',
        url: CUSTOM_TESTS_URL,
        params: {
          page: 1,
          paginates_per: 200,
        },
      });

      action = Actions.receiveCustomTestsList(response.data);
    } catch (e) {
      if (!isCancel(e)) {
        action = Actions.receiveCustomTestsError(e.message);
      }
    }

    action && dispatch(action);
  },
};

const { reducer } = LicenseHistorySlice;

export { reducer, Actions, Async, Selectors };
