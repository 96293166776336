import { createSlice } from '@reduxjs/toolkit';
import {
  request,
  generateCancelToken,
  cancelRequests,
  isCancel,
  ejectCancelInterceptor,
} from 'modules/Api/HttpClient';
import {
  STUDENTS_ACTIVATE_URL,
  STUDENTS_URL,
  STUDENTS_GET_TOTAL_URL,
  STUDENT_BY_ID_URL,
  STUDENTS_CREATE_URL,
  STUDENTS_UPDATE_URL,
  STUDENTS_DELETE_URL,
  STUDENTS_GENERATE_PASSWORD_URL,
  STUDENTS_IMPORT_URL,
  STUDENTS_SHOW_ASSOCIATION_URL,
  STUDENTS_REMOVE_AVATAR_URL,
  STUDENTS_SAVE_PASSPORT_URL,
  STUDENTS_REMOVE_PASSPORT_URL,
  STUDENTS_SAVE_PHOTO_URL,
  STUDENTS_REMOVE_PHOTO_URL,
  STUDENTS_EXPORT_URL,
  BASE_TESTS_URL,
  CUSTOM_TESTS_URL,
  GROUPS_SELECT_URL,
  UNITS_BY_GROUP_URL,
  SCHOOLS_BY_UNIT_URL,
  CLASSES_BY_SCHOOL_URL,
} from 'modules/Api/Routes';
import { formDataFromObj } from 'modules/Api/RequestData';
import { createStudentRequestModel } from 'modules/Users/Students/StudentUtils';
import { getISOStringFromStringDate } from 'modules/Utils';
import {
  getUTCDefaultStartDate,
  getUTCDefaultEndDate,
} from 'modules/Utils/Date';
import { ConvertPlacementToProgressCheck } from 'modules/Utils';

const currentStartDate = getUTCDefaultStartDate();
const currentEndDate = getUTCDefaultEndDate();

let cancelToken;

const initialState = {
  loading: false,
  loadingStudent: false,
  error: null,
  data: {
    page: 1,
    perPage: 10,
    search: '',
    groupId: null,
    unitId: null,
    schoolId: null,
    classId: null,
    testTag: null,
    testId: null,
    customTestId: null,
    whitelabelId: null,
    hasUtms: false,
    startDate: currentStartDate,
    endDate: currentEndDate,
    sort: {
      sortType: null,
      sortBy: null,
    },
    totalizers: {
      loading: false,
      totalPerDay: 0,
      totalPerDayPercent: 0,
      totalPerMonth: 0,
      totalPerMonthPercent: 0,
      totalPerSixMonths: 0,
      totalPerSixMonthsPercent: 0,
      totalPerYear: 0,
      totalPerYearPercent: 0,
    },
  },
  groups: {
    data: [],
    loading: false,
  },
  units: {
    data: [],
    loading: false,
  },
  schools: {
    data: [],
    loading: false,
  },
  classes: {
    data: [],
    loading: false,
  },
  tests: {
    data: [],
    loading: false,
  },
  customTests: {
    data: [],
    loading: false,
  },
  exportFilter: {
    startDate: currentStartDate,
    endDate: currentEndDate,
    groupId: null,
    unitId: null,
    schoolId: null,
    classId: null,
    testTag: null,
    testId: null,
    customTestId: null,
    whitelabelId: null,
    extension: 'csv',
    link: '',
    loading: false,
    groups: {
      data: [],
      loading: false,
    },
    units: {
      data: [],
      loading: false,
    },
    schools: {
      data: [],
      loading: false,
    },
    classes: {
      data: [],
      loading: false,
    },
  },
  isDeleting: false,
};

const studentSlice = createSlice({
  name: 'student',
  initialState,
  reducers: {
    cancelRequests: () => {
      cancelToken?.cancel();
      cancelRequests();
    },
    cleanState: () => ({ ...initialState }),
    /**
     * indicate that a request is started
     */
    toggleHasUtms: (state) => {
      state.data.hasUtms = !state.data.hasUtms;
    },
    changeSort: (state, action) => {
      const newSort = action.payload;
      state.data.sort.sortType =
        state.data.sort.sortBy === newSort && state.data.sort.sortType === 'ASC'
          ? 'DESC'
          : 'ASC';
      state.data.sort.sortBy = action.payload;
    },
    changeExportFilter: (state, action) => {
      state.exportFilter = {
        ...state.exportFilter,
        [action.payload?.param]: action.payload?.value,
      };
    },
    requestStudent: (state) => {
      state.loading = true;
      state.error = null;
    },
    changePerPage: (state, action) => {
      state.data.perPage = action.payload;
      state.data.page = 1;
    },
    requestStudentsTotalizers: (state) => {
      state.data.totalizers.loading = true;
      state.error = null;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setIsDeleting: (state, action) => {
      state.isDeleting = action.payload;
    },
    setLoadingStudent: (state, action) => {
      state.loadingStudent = action.payload;
    },
    /**
     * receive a success response
     */
    receiveRequestSuccess: (state) => {
      state.loading = false;
    },
    /**
     * receive a success student list response
     */
    receiveStudentList: (state, action) => {
      state.loading = false;
      state.data = {
        ...state.data,
        students: action.payload.students,
        total: action.payload.total_items,
        url_export_csv: action.payload.url_export_csv,
        url_export_xls: action.payload.url_export_xls,
      };
    },
    receiveStudentsTotalizers: (state, action) => {
      state.data.totalizers.loading = false;
      state.data.totalizers = {
        ...state.data.totalizers,
        total: action.payload.total_students,
        totalByMonth: action.payload.by_month,
      };
    },
    /**
     * receive an error response
     */
    receiveStudentError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    receiveStudentsTotalizersError: (state, action) => {
      state.data.totalizers.loading = false;
      state.error = action.payload;
    },
    changeStudentsPage: (state, action) => {
      state.data.page = action.payload;
    },
    changeStudentsSearch: (state, action) => {
      state.data.search = action.payload;
    },
    updateStudentOnList: (state, action) => {
      const updStudent = action.payload;
      const index = state.data?.students?.findIndex(
        (student) => student.id === updStudent.id
      );

      if (index >= 0) state.data.students.splice(index, 1, updStudent);
    },
    changeStudentsFilters: (state, action) => {
      state.data.search = action.payload.search || '';
      state.data.startDate = action.payload.start_date || currentStartDate;
      state.data.endDate = action.payload.end_date || currentEndDate;
      state.data.groupId = action.payload.group_id || null;
      state.data.unitId = action.payload.unit_id || null;
      state.data.schoolId = action.payload.school_id || null;
      state.data.classId = action.payload.class_id || null;
      state.data.testTag = action.payload?.test_tag || null;
      state.data.testId = action.payload?.test_id || null;
      state.data.customTestId = action.payload?.custom_test_id || null;
      state.data.whitelabelId = action.payload.whitelabel_id || null;
      state.data.page = isNaN(action?.payload?.page)
        ? 1
        : Number(action?.payload?.page);
      state.data.perPage = isNaN(action?.payload?.paginates_per)
        ? state.data.perPage
        : Number(action?.payload?.paginates_per);

      state.exportFilter.startDate =
        action.payload.start_date || currentStartDate;
      state.exportFilter.endDate = action.payload.end_date || currentEndDate;
      state.exportFilter.groupId = action.payload.group_id || null;
      state.exportFilter.unitId = action.payload.unit_id || null;
      state.exportFilter.schoolId = action.payload.school_id || null;
      state.exportFilter.classId = action.payload.class_id || null;
      state.exportFilter.testTag = action.payload?.test_tag || null;
      state.exportFilter.testId = action.payload?.test_id || null;
      state.exportFilter.customTestId = action.payload?.custom_test_id || null;
      state.exportFilter.whitelabelId = action.payload.whitelabel_id || null;
    },
    clearStudentsFilters: (state) => {
      state.data.search = '';
      state.data.startDate = currentStartDate;
      state.data.endDate = currentEndDate;
      state.data.page = 1;
      state.data.perPage = 10;
      state.data.sort.sortType = null;
      state.data.sort.sortBy = null;
      state.data.groupId = null;
      state.data.unitId = null;
      state.data.schoolId = null;
      state.data.classId = null;
      state.data.testTag = null;
      state.data.testId = null;
      state.data.customTestId = null;
      state.data.whitelabelId = null;
    },
    requestExportLinks: (state) => {
      state.exportFilter.link = '';
      state.exportFilter.loading = true;
    },
    receiveExportLinks: (state, action) => {
      state.exportFilter.link =
        action?.payload?.[`link_download_${state.exportFilter.extension}`];
      state.exportFilter.loading = false;
    },
    receiveExportLinksError: (state, action) => {
      state.exportFilter.loading = false;
      state.error = action.payload;
    },
    requestGroups: (state) => {
      state.groups.loading = true;
      state.exportFilter.groups.loading = true;
    },
    receiveGroupsList: (state, action) => {
      state.groups.loading = false;
      state.groups.data = action.payload;
      state.exportFilter.groups.loading = false;
      state.exportFilter.groups.data = action.payload;
    },
    receiveExportGroupsList: (state, action) => {
      state.groups.loading = false;
      state.exportFilter.groups.loading = false;
      state.exportFilter.groups.data = action.payload;
    },
    receiveGroupsError: (state, action) => {
      state.groups.loading = false;
      state.exportFilter.groups.loading = false;
      state.error = action.payload;
    },
    requestUnits: (state) => {
      state.units.loading = true;
      state.exportFilter.units.loading = true;
    },
    receiveUnitsList: (state, action) => {
      state.units.loading = false;
      state.units.data = action.payload;
      state.exportFilter.units.loading = false;
      state.exportFilter.units.data = action.payload;
    },
    receiveExportUnitsList: (state, action) => {
      state.units.loading = false;
      state.exportFilter.units.loading = false;
      state.exportFilter.units.data = action.payload;
    },
    receiveUnitsError: (state, action) => {
      state.units.loading = false;
      state.exportFilter.units.loading = false;
      state.error = action.payload;
    },
    requestSchools: (state) => {
      state.schools.loading = true;
      state.exportFilter.schools.loading = true;
    },
    receiveSchoolsList: (state, action) => {
      state.schools.loading = false;
      state.schools.data = action.payload;
      state.exportFilter.schools.loading = false;
      state.exportFilter.schools.data = action.payload;
    },
    receiveExportSchoolsList: (state, action) => {
      state.schools.loading = false;
      state.exportFilter.schools.loading = false;
      state.exportFilter.schools.data = action.payload;
    },
    receiveSchoolsError: (state, action) => {
      state.schools.loading = false;
      state.exportFilter.schools.loading = false;
      state.error = action.payload;
    },
    requestClasses: (state) => {
      state.classes.loading = true;
      state.exportFilter.classes.loading = true;
    },
    receiveClassesList: (state, action) => {
      state.classes.loading = false;
      state.classes.data = action.payload;
      state.exportFilter.classes.loading = false;
      state.exportFilter.classes.data = action.payload;
    },
    receiveExportClassesList: (state, action) => {
      state.classes.loading = false;
      state.exportFilter.classes.loading = false;
      state.exportFilter.classes.data = action.payload;
    },
    receiveClassesError: (state, action) => {
      state.classes.loading = false;
      state.exportFilter.classes.loading = false;
      state.error = action.payload;
    },
    requestBaseTests: (state) => {
      state.tests = {
        ...state.tests,
        loading: true,
      };
    },
    receiveBaseTestsList: (state, action) => {
      state.tests = {
        ...state.tests,
        loading: false,
        data: ConvertPlacementToProgressCheck(
          action.payload.content?.tests,
          action.payload?.hideProgressCheck
        ),
      };
    },
    receiveBaseTestsError: (state, action) => {
      state.tests = {
        ...state.tests,
        loading: false,
      };
      state.error = action.payload;
    },
    requestCustomTests: (state) => {
      state.customTests = {
        ...state.customTests,
        loading: true,
      };
    },
    receiveCustomTestsList: (state, action) => {
      state.customTests = {
        ...state.customTests,
        loading: false,
        data: action.payload.content?.custom_tests,
      };
    },
    receiveCustomTestsError: (state, action) => {
      state.customTests = {
        ...state.customTests,
        loading: false,
      };
      state.error = action.payload;
    },
  },
});

const Actions = studentSlice.actions;

const Selectors = {
  fetchListData: (state) => state.student,
  studentLoading: ({ student: { loading, loadingStudent } }) => ({
    loading,
    loadingStudent,
  }),
};

const Async = {
  setActivated:
    ({ data: student, activated, onSuccess, onError }) =>
    async () => {
      try {
        const data = new FormData();
        data.set('id', student.id);
        data.set('activate', activated);

        const response = await request({
          method: 'PUT',
          url: STUDENTS_ACTIVATE_URL,
          data,
        });

        onSuccess(response);
      } catch (e) {
        onError(e);
      }
    },

  fetchStudentList: () => async (dispatch, getState) => {
    const {
      student: {
        data: {
          page,
          perPage,
          search,
          startDate,
          groupId,
          unitId,
          schoolId,
          classId,
          testId,
          customTestId,
          whitelabelId,
          endDate,
          hasUtms,
          sort: { sortType, sortBy },
        },
      },
    } = getState();

    ejectCancelInterceptor();
    cancelToken?.cancel();
    cancelToken = generateCancelToken();

    let action;

    dispatch(Actions.requestStudent());

    try {
      const response = await request({
        cancelToken: cancelToken.token,
        method: 'GET',
        url: STUDENTS_URL,
        params: {
          page,
          paginates_per: perPage,
          search,
          whitelabel_id: whitelabelId,
          group_id: groupId,
          unit_id: unitId,
          school_id: schoolId,
          class_id: classId,
          test_id: testId,
          custom_test_id: customTestId,
          has_utms: hasUtms,
          start_date: getISOStringFromStringDate(startDate),
          end_date: getISOStringFromStringDate(endDate),
          sort: sortType,
          sort_by: sortBy,
        },
      });

      action = Actions.receiveStudentList(response.data.content);
    } catch (e) {
      if (!isCancel(e)) {
        action = Actions.receiveStudentError(e.message);
      }
    }

    action && dispatch(action);
  },

  fetchStudentTotalizers: () => async (dispatch) => {
    let action;

    dispatch(Actions.requestStudentsTotalizers());

    try {
      const response = await request({
        method: 'GET',
        url: STUDENTS_GET_TOTAL_URL,
      });

      action = Actions.receiveStudentsTotalizers(response.data.content);
    } catch (e) {
      action = Actions.receiveStudentsTotalizersError(e.message);
    }

    dispatch(action);
  },

  getStudentById:
    ({ id, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.setLoadingStudent(true));

      try {
        const response = await request({
          method: 'GET',
          url: `${STUDENT_BY_ID_URL}?id=${id}`,
        });

        onSuccess(response);
      } catch (e) {
        onError(e);
      } finally {
        dispatch(Actions.setLoadingStudent(false));
      }
    },

  createStudent:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestStudent());
      try {
        const parsedStudent = createStudentRequestModel(data);

        const studentData = formDataFromObj(parsedStudent);

        const response = await request({
          method: 'POST',
          url: STUDENTS_CREATE_URL,
          data: studentData,
        });

        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.receiveStudentError());
        onError(e);
      }
    },

  updateStudent:
    ({ data, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestStudent());
      try {
        const parsedStudent = createStudentRequestModel(data);

        const studentData = formDataFromObj(parsedStudent);

        const response = await request({
          method: 'PUT',
          url: STUDENTS_UPDATE_URL,
          data: studentData,
        });

        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.receiveStudentError());
        onError(e);
      }
    },

  generateNewStudentPassword:
    ({ id, onSuccess, onError }) =>
    async () => {
      try {
        const data = formDataFromObj({ id });

        const response = await request({
          method: 'PUT',
          url: STUDENTS_GENERATE_PASSWORD_URL,
          data,
        });

        onSuccess(response);
      } catch (e) {
        onError(e);
      }
    },

  deleteStudent:
    ({ id, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.setIsDeleting(true));

      try {
        const response = await request({
          method: 'DELETE',
          url: `${STUDENTS_DELETE_URL}?id=${id}`,
        });

        onSuccess(response);
        dispatch(Actions.setIsDeleting(false));
      } catch (e) {
        onError(e);
        dispatch(Actions.setIsDeleting(false));
      }
    },

  importStudents:
    ({ file, scheduler, onSuccess, onError, onUploadProgress }) =>
    async () => {
      const data = new FormData();
      data.set('file', file);
      scheduler && data.set('scheduler', scheduler);

      try {
        const response = await request({
          method: 'POST',
          url: STUDENTS_IMPORT_URL,
          data,
          onUploadProgress,
        });

        onSuccess(response);
      } catch (e) {
        onError(e);
      }
    },

  showAssociationNames:
    ({ id, onSuccess, onError }) =>
    async () => {
      try {
        const response = await request({
          method: 'GET',
          url: `${STUDENTS_SHOW_ASSOCIATION_URL}?id=${id}`,
        });

        onSuccess(response);
      } catch (e) {
        onError(e);
      }
    },

  removeAvatar:
    ({ id, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.requestStudent());
      try {
        const data = formDataFromObj({ id });

        const response = await request({
          method: 'PUT',
          url: STUDENTS_REMOVE_AVATAR_URL,
          data,
        });
        dispatch(Actions.receiveRequestSuccess());
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.receiveStudentError());
        onError(e);
      }
    },

  savePassportImage:
    ({ studentId, image, onSuccess, onError }) =>
    async (dispatch) => {
      try {
        const data = formDataFromObj({ id: studentId, passport_image: image });

        const response = await request({
          method: 'PUT',
          url: STUDENTS_SAVE_PASSPORT_URL,
          data,
        });

        const updUser = response?.data?.content;

        if (updUser) dispatch(Actions.updateStudentOnList(updUser));

        onSuccess(response);
      } catch (e) {
        onError(e);
      }
    },

  removePassportImage:
    ({ studentId, onSuccess, onError }) =>
    async (dispatch) => {
      try {
        const data = formDataFromObj({ id: studentId });

        const response = await request({
          method: 'PUT',
          url: STUDENTS_REMOVE_PASSPORT_URL,
          data,
        });

        const updUser = response?.data?.content;

        if (updUser) dispatch(Actions.updateStudentOnList(updUser));

        onSuccess(response);
      } catch (e) {
        onError(e);
      }
    },

  savePhoto:
    ({ studentId, image, onSuccess, onError }) =>
    async (dispatch) => {
      try {
        const data = formDataFromObj({ id: studentId, photo: image });

        const response = await request({
          method: 'PUT',
          url: STUDENTS_SAVE_PHOTO_URL,
          data,
        });

        const updUser = response?.data?.content;

        if (updUser) dispatch(Actions.updateStudentOnList(updUser));

        onSuccess(response);
      } catch (e) {
        onError(e);
      }
    },

  removePhoto:
    ({ studentId, onSuccess, onError }) =>
    async (dispatch) => {
      try {
        const data = formDataFromObj({ id: studentId });

        const response = await request({
          method: 'PUT',
          url: STUDENTS_REMOVE_PHOTO_URL,
          data,
        });

        const updUser = response?.data?.content;

        if (updUser) dispatch(Actions.updateStudentOnList(updUser));

        onSuccess(response);
      } catch (e) {
        onError(e);
      }
    },

  fetchGroupsList:
    ({ isExport } = {}) =>
    async (dispatch) => {
      let action;

      dispatch(Actions.requestGroups());

      try {
        const response = await request({
          method: 'GET',
          url: `${GROUPS_SELECT_URL}?sort=ASC&sort_by=name`,
        });

        const responseGroups = response?.data?.content?.groups || [];

        const groups = [
          {
            id: 'EDUSYNCH',
            name: 'EduSynch',
          },
          ...responseGroups,
        ];

        action = isExport
          ? Actions.receiveExportGroupsList(groups)
          : Actions.receiveGroupsList(groups);
      } catch (e) {
        if (!isCancel(e)) {
          action = Actions.receiveGroupsError(e.message);
        }
      }

      action && dispatch(action);
    },

  fetchUnitsList:
    ({ isExport, groupId } = {}) =>
    async (dispatch) => {
      let action;

      dispatch(Actions.requestUnits());

      try {
        const response = await request({
          method: 'GET',
          url: `${UNITS_BY_GROUP_URL}?group_id=${groupId}`,
        });

        action = isExport
          ? Actions.receiveExportUnitsList(response?.data?.content)
          : Actions.receiveUnitsList(response?.data?.content);
      } catch (e) {
        if (!isCancel(e)) {
          action = Actions.receiveUnitsError(e.message);
        }
      }

      action && dispatch(action);
    },

  fetchSchoolsList:
    ({ isExport, unitId } = {}) =>
    async (dispatch) => {
      let action;

      dispatch(Actions.requestSchools());

      try {
        const response = await request({
          method: 'GET',
          url: `${SCHOOLS_BY_UNIT_URL}?unit_id=${unitId}`,
        });

        action = isExport
          ? Actions.receiveExportSchoolsList(response.data.content.schools)
          : Actions.receiveSchoolsList(response.data.content.schools);
      } catch (e) {
        if (!isCancel(e)) {
          action = Actions.receiveSchoolsError(e.message);
        }
      }

      action && dispatch(action);
    },

  fetchClassesList:
    ({ isExport, schoolId } = {}) =>
    async (dispatch) => {
      let action;

      dispatch(Actions.requestClasses());

      try {
        const response = await request({
          method: 'GET',
          url: `${CLASSES_BY_SCHOOL_URL}?school_id=${schoolId}`,
        });

        action = isExport
          ? Actions.receiveExportClassesList(response.data.content)
          : Actions.receiveClassesList(response.data.content);
      } catch (e) {
        if (!isCancel(e)) {
          action = Actions.receiveClassesError(e.message);
        }
      }

      action && dispatch(action);
    },

  fetchBaseTestsList: (hideProgressCheck) => async (dispatch) => {
    let action;

    dispatch(Actions.requestBaseTests());

    try {
      const response = await request({
        method: 'GET',
        url: BASE_TESTS_URL,
        params: {
          show_all_tests: true,
          sort: 'ASC',
          sort_by: 'name',
        },
      });

      action = Actions.receiveBaseTestsList({
        ...response.data,
        hideProgressCheck,
      });
    } catch (e) {
      if (!isCancel(e)) {
        action = Actions.receiveBaseTestsError(e.message);
      }
    }

    action && dispatch(action);
  },

  fetchCustomTestsList: () => async (dispatch) => {
    let action;

    dispatch(Actions.requestCustomTests());

    try {
      const response = await request({
        method: 'GET',
        url: CUSTOM_TESTS_URL,
        params: {
          page: 1,
          paginates_per: 200,
        },
      });

      action = Actions.receiveCustomTestsList(response.data);
    } catch (e) {
      if (!isCancel(e)) {
        action = Actions.receiveCustomTestsError(e.message);
      }
    }

    action && dispatch(action);
  },

  fetchExportSubmit:
    ({ onSuccess, onError }) =>
    async (dispatch, getState) => {
      const {
        student: {
          data: { search },
          exportFilter,
        },
      } = getState();
      let action;

      dispatch(Actions.requestExportLinks());

      try {
        const response = await request({
          method: 'POST',
          url: STUDENTS_EXPORT_URL,
          data: {
            file_type: exportFilter?.extension,
            start_date: getISOStringFromStringDate(exportFilter?.startDate),
            end_date: getISOStringFromStringDate(exportFilter?.endDate),
            whitelabel_id: exportFilter?.whitelabelId,
            group_id: exportFilter?.groupId,
            unit_id: exportFilter?.unitId,
            school_id: exportFilter?.schoolId,
            class_id: exportFilter?.classId,
            test_id: exportFilter?.testId,
            custom_test_id: exportFilter?.customTestId,
            search,
          },
        });

        action = Actions.receiveExportLinks(response.data.content);
        onSuccess(response.data.content);
      } catch (e) {
        action = Actions.receiveExportLinksError(e.message);
        onError();
      }

      dispatch(action);
    },
};

const { reducer } = studentSlice;

export { reducer, Actions, Async, Selectors };
