export const PREFIX = '/cms/v1';
export const AUTH_PREFIX = '/auth/v1';

export const AUTH_LOGIN_URL = `${AUTH_PREFIX}/authentications/login.json`;
export const AUTH_FORGOT_PASSWORD_URL = `${AUTH_PREFIX}/authentications/forgot_password`;
export const AUTH_REQUEST_FORGOT_PASSWORD_URL = `${AUTH_PREFIX}/authentications/request_forgot_password`;

export const PERMISSIONS_CHECK_URL = `${PREFIX}/user_permissions/check`;

export const SETTINGS_PROFILE_UPDATE_URL = `${PREFIX}/settings/update`;
export const SETTINGS_REMOVE_AVATAR_URL = `${PREFIX}/settings/remove_avatar`;
export const SETTINGS_AVATAR_BASE64_URL = `${PREFIX}/settings/avatar_base64`;

export const SETTINGS_UPDATE_URL = `${PREFIX}/general_settings/update`;
export const SETTINGS_FETCH_AI_EVALUATOR_URL = `${PREFIX}/general_settings/get_settings`;
export const SETTINGS_UPDATE_AI_EVALUATOR_URL = `${PREFIX}/general_settings/update`;
export const SETTINGS_REMOVE_AI_EVALUATOR_URL = `${PREFIX}/general_settings/remove_ai_evaluator_avatar`;
export const SETTINGS_URL = `${PREFIX}/general_settings/get_settings`;
export const SETTINGS_DELETE_IMAGE_URL = `${PREFIX}/general_settings/delete_images`;

export const ANTIFRAUDS_URL = `${PREFIX}/antifrauds`;
export const ANTIFRAUDS_CUSTOM_TESTS_URL = `${PREFIX}/antifrauds/custom-tests`;
export const ANTIFRAUD_EXPORT_URL = `${ANTIFRAUDS_URL}/export_list`;

export const ANTIFRAUD_SESSIONS_URL = `${PREFIX}/antifraud_sessions`;
export const ANTIFRAUD_SESSIONS_BY_ID_EVENTS_URL = (id) =>
  `${ANTIFRAUD_SESSIONS_URL}/${id}/events`;

export const ANTIFRAUD_SESSIONS_EXPORT_URL = `${ANTIFRAUD_SESSIONS_URL}/export_list`;

export const CATEGORIES_URL = `${PREFIX}/categories/categories`;

export const BASE_TESTS_URL = `${PREFIX}/tests/list`;
export const BASE_TEST_BY_ID_URL = `${PREFIX}/tests/get_test_by_id`;
export const BASE_TESTS_CREATE_URL = `${PREFIX}/tests/create`;
export const BASE_TESTS_UPDATE_URL = `${PREFIX}/tests/update`;

export const CUSTOM_TESTS_URL = `${PREFIX}/custom_tests/list`;
export const CUSTOM_TEST_BY_ID_URL = `${PREFIX}/custom_tests/get_custom_test_by_id`;
export const CUSTOM_TESTS_CREATE_URL = `${PREFIX}/custom_tests/create`;
export const CUSTOM_TESTS_UPDATE_URL = `${PREFIX}/custom_tests/update`;
export const CUSTOM_TESTS_DELETE_URL = `${PREFIX}/custom_tests/delete`;
export const CUSTOM_TEST_BY_WHITELABEL_URL = `${PREFIX}/custom_tests/get_custom_test_by_whitelabel`;
export const CUSTOM_TEST_BY_TEST_URL = `${PREFIX}/custom_tests/get_custom_test_by_test_id`;
export const CUSTOM_TESTS_CATEGORIES_URL = `${PREFIX}/custom_tests/get_custom_test_categories`;
export const CUSTOM_TEST_GRADES_URL = `${PREFIX}/custom_tests/get_custom_test_grades`;

export const GROUPS_URL = `${PREFIX}/groups/list`;
export const GROUP_BY_ID_URL = `${PREFIX}/groups/get_group_by_id`;
export const GROUPS_CREATE_URL = `${PREFIX}/groups/create`;
export const GROUPS_UPDATE_URL = `${PREFIX}/groups/update`;
export const GROUPS_DELETE_URL = `${PREFIX}/groups/delete`;

export const UNITS_URL = `${PREFIX}/units/list`;
export const UNIT_BY_ID_URL = `${PREFIX}/units/get_unit_by_id`;
export const UNITS_BY_GROUP_URL = `${PREFIX}/units/get_units_by_group_id`;
export const UNITS_CREATE_URL = `${PREFIX}/units/create`;
export const UNITS_UPDATE_URL = `${PREFIX}/units/update`;
export const UNITS_DELETE_URL = `${PREFIX}/units/delete`;

export const SCHOOLS_URL = `${PREFIX}/schools/list`;
export const SCHOOL_BY_ID_URL = `${PREFIX}/schools/get_school_by_id`;
export const SCHOOLS_BY_UNIT_URL = `${PREFIX}/schools/get_schools_by_unit_id`;
export const SCHOOLS_BY_GROUP_URL = `${PREFIX}/schools/get_schools_by_group_id`;
export const SCHOOLS_CREATE_URL = `${PREFIX}/schools/create`;
export const SCHOOLS_UPDATE_URL = `${PREFIX}/schools/update`;
export const SCHOOLS_DELETE_URL = `${PREFIX}/schools/delete`;

export const CLASSES_URL = `${PREFIX}/classes/list`;
export const CLASS_BY_ID_URL = `${PREFIX}/classes/get_class_by_id`;
export const CLASSES_BY_SCHOOL_URL = `${PREFIX}/classes/get_classes_by_school_id`;
export const CLASSES_CREATE_URL = `${PREFIX}/classes/create`;
export const CLASSES_UPDATE_URL = `${PREFIX}/classes/update`;
export const CLASSES_DELETE_URL = `${PREFIX}/classes/delete`;

export const COUNTRY_PERMISSIONS_URL = `${PREFIX}/country_permissions/list`;
export const COUNTRY_PERMISSIONS_COUNTRIES_URL = `${PREFIX}/country_permissions/get_countries`;
export const COUNTRY_PERMISSIONS_BY_UNIT_ID_URL = `${PREFIX}/country_permissions/get_country_permission_by_unit_id`;
export const COUNTRY_PERMISSIONS_CREATE_URL = `${PREFIX}/country_permissions/create`;
export const COUNTRY_PERMISSIONS_UPDATE_URL = `${PREFIX}/country_permissions/update`;
export const COUNTRY_PERMISSIONS_DELETE_URL = `${PREFIX}/country_permissions/delete`;

export const LANGUAGES_URL = `${PREFIX}/languages/list`;

export const TIMEZONES_URL = `${PREFIX}/timezones/list`;

export const USER_TEACHERS_URL = `${PREFIX}/user_teachers/list`;
export const USER_TEACHERS_ACTIVATE_URL = `${PREFIX}/user_teachers/activate`;
export const USER_TEACHERS_CREATE_URL = `${PREFIX}/user_teachers/create`;
export const USER_TEACHERS_UPDATE_URL = `${PREFIX}/user_teachers/update`;
export const USER_TEACHERS_DELETE_URL = `${PREFIX}/user_teachers/delete`;
export const USER_TEACHER_BY_ID_URL = `${PREFIX}/user_teachers/get_teacher_by_id`;

export const USER_SUPER_ADMINS_URL = `${PREFIX}/user_super_admins/list`;
export const USER_SUPER_ADMIN_BY_ID_URL = `${PREFIX}/user_super_admins/get_super_admin_by_id`;
export const USER_SUPER_ADMINS_CREATE_URL = `${PREFIX}/user_super_admins/create`;
export const USER_SUPER_ADMINS_UPDATE_URL = `${PREFIX}/user_super_admins/update`;
export const USER_SUPER_ADMINS_DELETE_URL = `${PREFIX}/user_super_admins/delete`;

export const USER_GROUP_ADMINS_URL = `${PREFIX}/user_group_admins/list`;
export const USER_GROUP_ADMIN_BY_ID_URL = `${PREFIX}/user_group_admins/get_group_admin_by_id`;
export const USER_GROUP_ADMINS_CREATE_URL = `${PREFIX}/user_group_admins/create`;
export const USER_GROUP_ADMINS_UPDATE_URL = `${PREFIX}/user_group_admins/update`;
export const USER_GROUP_ADMINS_DELETE_URL = `${PREFIX}/user_group_admins/delete`;

export const USER_UNIT_ADMINS_URL = `${PREFIX}/user_unit_admins/list`;
export const USER_UNIT_ADMINS_ACTIVATE_URL = `${PREFIX}/user_unit_admins/activate`;
export const USER_UNIT_ADMIN_BY_ID_URL = `${PREFIX}/user_unit_admins/get_unit_admin_by_id`;
export const USER_UNIT_ADMINS_CREATE_URL = `${PREFIX}/user_unit_admins/create`;
export const USER_UNIT_ADMINS_UPDATE_URL = `${PREFIX}/user_unit_admins/update`;
export const USER_UNIT_ADMINS_DELETE_URL = `${PREFIX}/user_unit_admins/delete`;

export const USER_SCHOOL_ADMINS_URL = `${PREFIX}/user_school_admins/list`;
export const USER_SCHOOL_ADMINS_ACTIVATE_URL = `${PREFIX}/user_school_admins/activate`;
export const USER_SCHOOL_ADMINS_CREATE_URL = `${PREFIX}/user_school_admins/create`;
export const USER_SCHOOL_ADMINS_UPDATE_URL = `${PREFIX}/user_school_admins/update`;
export const USER_SCHOOL_ADMINS_DELETE_URL = `${PREFIX}/user_school_admins/delete`;
export const USER_SCHOOL_ADMIN_BY_ID_URL = `${PREFIX}/user_school_admins/get_school_admin_by_id`;
export const USER_SCHOOL_ADMINS_BY_SCHOOL_URL = `${PREFIX}/user_school_admins/get_school_admins_by_school`;

export const USER_EVALUATORS_URL = `${PREFIX}/user_evaluators/list`;
export const USER_EVALUATORS_FOR_SELECTS_URL = `${PREFIX}/user_evaluators/list_for_selects`;
export const USER_EVALUATORS_ACTIVATE_URL = `${PREFIX}/user_evaluators/activate`;
export const USER_EVALUATOR_BY_ID_URL = `${PREFIX}/user_evaluators/get_evaluator_by_id`;
export const USER_EVALUATORS_EVALUATABLES_URL = `${PREFIX}/user_evaluators/get_evaluatables`;
export const USER_EVALUATORS_CREATE_URL = `${PREFIX}/user_evaluators/create`;
export const USER_EVALUATORS_UPDATE_URL = `${PREFIX}/user_evaluators/update`;
export const USER_EVALUATORS_DELETE_URL = `${PREFIX}/user_evaluators/delete`;

export const EVALUATORS_SCHEDULERS_IMPORT_URL = `${PREFIX}/evaluators_schedulers/import`;

export const USER_CREATORS_URL = `${PREFIX}/user_creators/list`;
export const USER_CREATOR_BY_ID_URL = `${PREFIX}/user_creators/get_creator_by_id`;
export const USER_CREATORS_CREATE_URL = `${PREFIX}/user_creators/create`;
export const USER_CREATORS_UPDATE_URL = `${PREFIX}/user_creators/update`;
export const USER_CREATORS_DELETE_URL = `${PREFIX}/user_creators/delete`;

export const STUDENTS_URL = `${PREFIX}/students/list`;
export const STUDENTS_ACTIVATE_URL = `${PREFIX}/students/activate`;
export const STUDENTS_GET_TOTAL_URL = `${PREFIX}/students/get_total_students`;
export const STUDENT_BY_ID_URL = `${PREFIX}/students/get_student_by_id`;
export const STUDENTS_GENERATE_PASSWORD_URL = `${PREFIX}/students/generate_new_password`;
export const STUDENTS_CREATE_URL = `${PREFIX}/students/create`;
export const STUDENTS_UPDATE_URL = `${PREFIX}/students/update`;
export const STUDENTS_DELETE_URL = `${PREFIX}/students/delete`;
export const STUDENTS_IMPORT_URL = `${PREFIX}/students/import`;
export const STUDENTS_SHOW_ASSOCIATION_URL = `${PREFIX}/students/show_association_names`;
export const STUDENTS_REMOVE_AVATAR_URL = `${PREFIX}/students/remove_avatar`;
export const STUDENTS_SAVE_PASSPORT_URL = `${PREFIX}/students/save_passport_image`;
export const STUDENTS_REMOVE_PASSPORT_URL = `${PREFIX}/students/remove_passport_image`;
export const STUDENTS_SAVE_PHOTO_URL = `${PREFIX}/students/save_photo`;
export const STUDENTS_REMOVE_PHOTO_URL = `${PREFIX}/students/remove_photo`;
export const STUDENTS_EXPORT_URL = `${PREFIX}/students/export_list`;

export const STUDENTS_BY_GROUP_URL = `${PREFIX}/students/get_students_by_group`;
export const STUDENTS_BY_UNIT_URL = `${PREFIX}/students/get_students_by_unit`;
export const STUDENTS_BY_SCHOOL_URL = `${PREFIX}/students/get_students_by_school`;
export const STUDENTS_BY_CLASS_URL = `${PREFIX}/students/get_students_by_class`;

export const STUDENT_LICENSES_CREATE_URL = `${PREFIX}/student_licenses/create`;
export const STUDENT_LICENSES_UPDATE_URL = `${PREFIX}/student_licenses/update`;
export const STUDENT_LICENSES_DELETE_URL = `${PREFIX}/student_licenses/delete`;

export const LICENSES_URL = `${PREFIX}/licenses/list`;
export const LICENSE_BY_ID_URL = `${PREFIX}/licenses`;
export const LICENSE_BY_GROUP_URL = `${PREFIX}/licenses/get_licenses_by_group_id`;
export const LICENSE_BY_UNIT_URL = `${PREFIX}/licenses/get_licenses_by_unit_id`;
export const LICENSE_BY_SCHOOL_URL = `${PREFIX}/licenses/get_licenses_by_school_id`;
export const LICENSE_BY_CLASS_URL = `${PREFIX}/licenses/get_licenses_by_class_id`;
export const LICENSES_MOUNT_FIELDS_URL = `${PREFIX}/licenses/mount_fields_license_amount`;
export const LICENSES_STUDENT_URL = `${PREFIX}/student_licenses/get_student_license_by_license`;
export const LICENSES_CREATE_URL = `${PREFIX}/licenses`;
export const LICENSES_GROUP_BY_CUSTOM_URL = `${PREFIX}/licenses/get_group_by_custom`;
export const LICENSES_UNIT_BY_CUSTOM_URL = `${PREFIX}/licenses/get_unit_by_custom`;
export const LICENSES_SCHOOL_BY_CUSTOM_URL = `${PREFIX}/licenses/get_school_by_custom`;
export const LICENSES_CLASS_BY_CUSTOM_URL = `${PREFIX}/licenses/get_class_by_custom`;

export const OVERALL_SCORINGS_URL = `${PREFIX}/overall_scorings/list`;
export const OVERALL_SCORING_BY_ID_URL = `${PREFIX}/overall_scorings/get_overall_scoring_by_id`;
export const OVERALL_SCORINGS_APROVED_URL = `${PREFIX}/overall_scorings/approved`;
export const OVERALL_SCORINGS_UPDATE_URL = `${PREFIX}/overall_scorings/update`;
export const OVERALL_SCORINGS_GENERATE_CERTIFICATE_URL = `${PREFIX}/overall_scorings/generate_ellt_certificate`;
export const OVERALL_SCORINGS_GENERATE_REPORT_URL = `${PREFIX}/overall_scorings/generate_ellt_report`;
export const OVERALL_SCORINGS_CHANGE_EVALUATOR_URL = `${PREFIX}/overall_scorings/change_evaluator`;
export const OVERALL_SCORINGS_EXPORT_URL = `${PREFIX}/overall_scorings/export_list`;

export const PRACTICE_URL = `${PREFIX}/practice.json`;
export const PRACTICE_RESULTS_INSTITUTIONS_URL = `${PREFIX}/practice_results_institutions/list`;
export const PRACTICE_RESULTS_INSTITUTIONS_SHOW_URL = `${PREFIX}/practice_results_institutions/show`;
export const PRACTICE_RESULTS_INSTITUTIONS_HISTORY_URL = `${PREFIX}/practice_results_institutions/get_history_details`;
export const PRACTICE_RESULTS_INSTITUTIONS_EXPORT_URL = `${PREFIX}/practice_results_institutions/export_list`;
export const PRACTICE_RESULTS_INSTITUTIONS_ANALYTICS_URL = `${PREFIX}/practice_results_institutions/analytics`;
export const PRACTICE_RESULTS_INSTITUTIONS_ANALYTICS_EXPORT_URL = `${PREFIX}/practice_results_institutions/export_analytics`;
export const PRACTICE_RESULTS_HISTORIES_URL = `${PREFIX}/history_practices/list`;

export const PRACTICE_RESULTS_STUDENTS_URL = `${PREFIX}/practice_results_students/list`;
export const PRACTICE_RESULTS_STUDENTS_DETAILS_URL = `${PREFIX}/practice_results_students/details_v2`;
export const PRACTICE_RESULTS_STUDENTS_EXPORT_URL = `${PREFIX}/practice_results_students/export_list`;
export const PRACTICE_RESULTS_STUDENTS_EXPORT_DETAILS_URL = `${PREFIX}/practice_results_students/export_details`;

export const MAINTENANTE_URL = '/common/applications/maintenance.json';

export const WHITELABELS_URL = `${PREFIX}/whitelabels/list`;
export const WHITELABELS_BY_USER_URL = `${PREFIX}/whitelabels/get_whitelabels_by_user`;
export const WHITELABEL_CREATE_URL = `${PREFIX}/whitelabels/create`;
export const WHITELABEL_UPDATE_URL = `${PREFIX}/whitelabels/update`;
export const WHITELABEL_DELETE_URL = `${PREFIX}/whitelabels/delete`;
export const WHITELABEL_DELETE_IMAGE_URL = `${PREFIX}/whitelabels/delete_images`;
export const WHITELABELS_INSTITUTIONS_URL = `${PREFIX}/whitelabels/get_institutions`;
export const WHITELABEL_BY_ID_URL = `${PREFIX}/whitelabels/get_whitelabel_by_id`;
export const WHITELABEL_GENERATE_API_KEY_URL = `${PREFIX}/whitelabels/generate_api_key`;
export const EXTERNAL_APPLICATIONS_URL = `${PREFIX}/external_applications/get_external_applications`;

export const PROCTORING_SETTINGS_LIST_URL = `${PREFIX}/proctoring_settings/list`;
export const PROCTORING_SETTINGS_BY_WHITELABEL_OR_EXTERNAL_APP_ID_URL = `${PREFIX}/proctoring_settings/get_proctoring_setting_by_whitelabel_or_external_app_id`;
export const PROCTORING_SETTINGS_BY_ID_URL = `${PREFIX}/proctoring_settings/get_proctoring_setting`;
export const PROCTORING_SETTINGS_CREATE_URL = `${PREFIX}/proctoring_settings/create`;
export const PROCTORING_SETTINGS_UPDATE_URL = `${PREFIX}/proctoring_settings/update`;

export const ALLOWED_URLS_BY_ID_URL = `${PREFIX}/allowed_urls/get_by_proctoring_setting_id`;
export const ALLOWED_URLS_CREATE_URL = `${PREFIX}/allowed_urls/create`;
export const ALLOWED_URLS_UPDATE_URL = `${PREFIX}/allowed_urls/update`;
export const ALLOWED_URLS_DELETE_URL = `${PREFIX}/allowed_urls/delete`;

export const NOTIFICATIONS_URL = `${PREFIX}/notifications/list`;
export const NOTIFICATION_BY_ID_URL = `${PREFIX}/notifications`;
export const NOTIFICATIONS_CREATE_URL = `${PREFIX}/notifications/create`;
export const NOTIFICATIONS_UPDATE_URL = `${PREFIX}/notifications/update`;
export const NOTIFICATIONS_DELETE_URL = `${PREFIX}/notifications/delete`;

export const SIMULATIONS_URL = `${PREFIX}/simulations.json`;

export const SIMULATES_BASE_URL = `${PREFIX}/simulates/list_base`;
export const SIMULATES_CUSTOM_URL = `${PREFIX}/simulates/list_custom`;
export const SIMULATES_BY_ID_URL = `${PREFIX}/simulates/get_simulate_by_id`;
export const SIMULATES_CHANGE_LIVE_URL = `${PREFIX}/simulates/change_live`;

export const SIMULATION_RESULTS_URL = `${PREFIX}/simulation_results/get_simulation_results`;
export const SIMULATION_RESULTS_BY_ID_URL = `${PREFIX}/simulation_results/get_simulation_result_by_id`;
export const SIMULATION_RESULTS_GENERATE_REPORT_URL = `${PREFIX}/simulation_results/get_cefr_report`;
export const SIMULATION_RESULTS_EXPORT_URL = `${PREFIX}/simulation_results/export_list`;

export const CUSTOM_TEST_RESULTS_URL = `${PREFIX}/custom_test_results/list`;
export const CUSTOM_TEST_RESULTS_GENERATE_CERTIFICATE_URL = `${PREFIX}/custom_test_results/custom_tests/generate_ellt_certificate`;
export const CUSTOM_TEST_RESULTS_GENERATE_REPORT_URL = `${PREFIX}/custom_test_results/custom_tests/generate_ellt_report`;
export const CUSTOM_TEST_RESULTS_EXPORT_URL = `${PREFIX}/custom_test_results/export_list`;

export const TEST_SUBMISSIONS_NORMALS_URL = `${PREFIX}/test_submission_normals`;
export const TEST_SUBMISSIONS_NORMALS_EXPORT_URL = `${PREFIX}/test_submission_normals/export_list`;
export const TEST_SUBMISSIONS_CUSTOM_URL = `${PREFIX}/test_submission_customs`;
export const TEST_SUBMISSIONS_CUSTOM_EXPORT_URL = `${PREFIX}/test_submission_customs/export_list`;
export const TEST_SUBMISSIONS_INVALIDS_URL = `${PREFIX}/test_submission_invalids`;
export const TEST_SUBMISSIONS_PRACTICE_URL = `${PREFIX}/practice_results_submissions`;

export const ANSWER_SHEETS_EXPORT_URL = `${PREFIX}/answer_sheets/export_answer_sheets`;

export const ANSWER_PARTIAL_TEST_BY_ID_URL = `${PREFIX}/answers_partial_test/get_answers_partial_test`;
export const ANSWER_PRACTICE_PARTIAL_TEST_BY_ID_URL = `${PREFIX}/practice_results_submissions/get_practice_answers`;
export const ANSWER_SHOW_PRACTICE_ANSWER_BY_ID_URL = `${PREFIX}/practice_results_submissions/show_practice_answer`;
export const ANSWER_PARTIAL_TEST_SHOW_URL = `${PREFIX}/answers_partial_test/show_answer_partial_test`;
export const ANSWER_PARTIAL_TEST_RANGE_URL = `${PREFIX}/answers_partial_test/get_question_range_to_answer_partial_test`;
export const ANSWER_PARTIAL_TEST_UPDATE_TO_ANSWER_URL = `${PREFIX}/answers_partial_test/update_answer_partial_test_reading_or_listening`;
export const ANSWER_PARTIAL_TEST_UPDATE_URL = `${PREFIX}/answers_partial_test/update_answer_partial_test_writing_or_speaking`;
export const ANSWER_PARTIAL_TEST_CUSTOM_UPDATE_URL = `${PREFIX}/answers_partial_test/update_answer_partial_test_writing_or_speaking_custom`;

export const USER_API_URL = `${PREFIX}/user_api/list`;
export const USER_API_BY_ID_URL = `${PREFIX}/user_api/get_user_by_id`;
export const USER_API_CREATE_URL = `${PREFIX}/user_api/create`;
export const USER_API_UPDATE_URL = `${PREFIX}/user_api/update`;
export const USER_API_DELETE_URL = `${PREFIX}/user_api/delete`;

export const EXTERNAL_SOFTWARE_URL = `${PREFIX}/external_software/list`;
export const EXTERNAL_SOFTWARE_BY_ID_URL = `${PREFIX}/external_software/get_external_software_by_id`;
export const EXTERNAL_SOFTWARE_CREATE_URL = `${PREFIX}/external_software/create`;
export const EXTERNAL_SOFTWARE_UPDATE_URL = `${PREFIX}/external_software/update`;
export const EXTERNAL_SOFTWARE_DELETE_URL = `${PREFIX}/external_software/delete`;

export const INTEGRATIONS_URL = `${PREFIX}/integrations`;
export const INTEGRATIONS_LIST_URL = `${PREFIX}/integrations/list`;
export const INTEGRATION_BY_ID_URL = `${PREFIX}/integrations/get_integration_by_id`;
export const INTEGRATIONS_CREATE_URL = `${PREFIX}/integrations/create`;
export const INTEGRATIONS_UPDATE_URL = `${PREFIX}/integrations/update`;
export const INTEGRATIONS_DELETE_URL = `${PREFIX}/integrations/delete`;

export const VERSIONS_URL = `${PREFIX}/versions/list`;
export const VERSION_BY_ID_URL = `${PREFIX}/versions/get_version`;
export const VERSIONS_CURRENT_URL = `${PREFIX}/versions/current_version`;
export const VERSIONS_CREATE_URL = `${PREFIX}/versions/create`;
export const VERSIONS_UPDATE_URL = `${PREFIX}/versions/update`;
export const VERSIONS_DELETE_URL = `${PREFIX}/versions/delete`;

export const PURCHASES_URL = `${PREFIX}/purchases/list`;
export const PURCHASES_EXPORT_URL = `${PREFIX}/purchases/export_list`;
export const PURCHASE_PLANS_URL = `${PREFIX}/purchases/get_purchase_plans`;

export const SUBSCRIPTIONS_URL = `${PREFIX}/subscriptions/list`;
export const SUBSCRIPTION_BY_ID_URL = `${PREFIX}/subscriptions/get_subscription_by_id`;
export const SUBSCRIPTIONS_INFORMATION_URL = `${PREFIX}/subscriptions/subscriptions_information`;
export const SUBSCRIPTIONS_UPDATE_CANCELED_URL = `${PREFIX}/subscriptions/update_canceled_subscription`;

export const DISCOUNTS_URL = `${PREFIX}/discounts/list`;
export const DISCOUNT_BY_ID_URL = `${PREFIX}/subscriptions/get_discount_by_id`;
export const DISCOUNTS_CREATE_URL = `${PREFIX}/discounts/create`;
export const DISCOUNTS_UPDATE_URL = `${PREFIX}/discounts/update`;
export const DISCOUNTS_DELETE_URL = `${PREFIX}/discounts/delete`;

export const PLANS_URL = `${PREFIX}/plans/list`;

export const CREATORS_URL = `${PREFIX}/user_creators/list`;

export const DASHBOARD_WIDGET = `${PREFIX}/dashboard/widget`;
export const DASHBOARD_EPROCTORING_EVENTS_ANALYTICS_URL = `${PREFIX}/dashboard/e_proctoring_events_widget`;
export const DASHBOARD_EPROCTORING_SESSIONS_COUNT_URL = `${PREFIX}/dashboard/e_proctoring_sessions_count_widget`;
export const DASHBOARD_COMPLETION_RATE_URL = `${PREFIX}/dashboard/completion_rate_widget`;

export const SETTINGS_DASHBOARD = `${PREFIX}/settings/dashboard`;

export const FEEDBACK_CREATE_URL = `${PREFIX}/feedbacks/send_feedback`;

export const EXERCISES_SHOW_NEXT_EXERCISE = `${PREFIX}/exercises/show_next_exercise`;

export const EXERCISES_CAN_SEND_TO_OTHER_EVALUATOR = `${PREFIX}/exercises/can_send_to_other_evaluator`;

export const LICENSE_HISTORY_LIST_URL = `${PREFIX}/history_licenses/list`;
export const LICENSE_HISTORY_BY_ID_URL = `${PREFIX}/history_licenses/get_history_licenses_by_license_id`;

export const GROUPS_SELECT_URL = `${PREFIX}/groups/select`;
export const UNITS_SELECT_URL = `${PREFIX}/units/select`;
export const SCHOOLS_SELECT_URL = `${PREFIX}/schools/select`;

export const LIVE_TESTS_URL = `${PREFIX}/live_tests/list`;
export const LIVE_TESTS_CREATE_URL = `${PREFIX}/live_tests/create`;
export const LIVE_TESTS_DELETE_URL = `${PREFIX}/live_tests/delete`;
export const LIVE_TESTS_UPDATE_URL = `${PREFIX}/live_tests/update`;

export const LIVE_TEST_CODES_URL = `${PREFIX}/live_tests/list_live_codes`;
export const LIVE_TEST_CODES_CREATE_URL = `${PREFIX}/live_tests/create_live_codes`;
export const LIVE_TEST_CODES_DELETE_URL = `${PREFIX}/live_tests/delete_live_code`;

export const LIVE_INTERVIEWS_LIST_URL = `${PREFIX}/live_tests/queue`;
export const LIVE_INTERVIEWS_START_LIVE_TEST_URL = `${PREFIX}/live_tests/start_live_test`;
export const LIVE_INTERVIEWS_END_LIVE_TEST_URL = `${PREFIX}/live_tests/end_live_test`;
export const LIVE_INTERVIEWS_INSTRUCTIONS_URL = `${PREFIX}/live_tests/get_instuctions`;

export const FEATURE_FLAGS_URL = `${PREFIX}/features/list`;
export const FEATURE_FLAG_BY_ID_URL = `${PREFIX}/features/get_feature_by_id`;
export const FEATURE_FLAGS_CREATE_URL = `${PREFIX}/features/create`;
export const FEATURE_FLAGS_UPDATE_URL = `${PREFIX}/features/update`;
export const FEATURE_FLAGS_DELETE_URL = `${PREFIX}/features/delete`;

export const PLANS_SUBSCRIPTION_URL = `${PREFIX}/plans`;
