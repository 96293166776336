const INVALID_TESTS = ['PLACEMENT_TEST', 'IELTS_FULL_2'];

const ConvertPlacementToProgressCheck = (tests, hidePlacementTest = false) => {
  if (!tests.length) return [];

  const VALID_TESTS = tests.reduce((prev, next) => {
    if (INVALID_TESTS.includes(next.tag)) {
      if (!hidePlacementTest && next.tag === 'PLACEMENT_TEST') {
        return [...prev, { ...next, name: 'Progress Check' }];
      }
    } else {
      return [...prev, next];
    }
    return prev;
  }, []);

  return VALID_TESTS;
};

export { ConvertPlacementToProgressCheck };
