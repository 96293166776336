import styled, { css } from 'styled-components/macro';

const InputControl = styled.div`
  position: relative;

  ${(props) =>
    props.inputErrors &&
    props.inputTouched &&
    css`
      &:not(:first-child) {
        svg {
          &.error {
            &:not(:last-child) {
              path {
                fill: ${(props) => props.theme.config.colors.tertiary};
                opacity: 0.8;
              }
            }
          }
        }
      }
    `}

  ${(props) =>
    props.hidden &&
    css`
      visibility: hidden;
      opacity: 0;
      position: absolute;
    `}

  ${(props) =>
    props.inputErrors &&
    props.inputTouched &&
    css`
      > div > svg {
        right: 30px !important;
      }
    `}

    ${(props) =>
    props.inputStrenghtPassword &&
    css`
      > div > svg {
        right: 65px !important;
      }
    `}

  > svg {
    position: absolute;
    top: 50%;
    left: 1.2rem;
    transform: translateY(-50%);

    &.AlertCircleSecondIcon {
      pointer-events: all;
      cursor: pointer;
      right: 1.6rem;
      left: auto;

      path {
        opacity: 1;
        fill: ${(props) => props.theme.config.colors.tertiary};
      }
    }
  }

  .input-default {
    padding-left: 1.6rem;
  }

  .input-default-password {
    > input {
      padding-left: 1.6rem;
    }
  }

  input {
    padding-left: 5rem;
    border: ${(props) =>
      props.borders
        ? `1px solid ${props.theme.config.colors.gray_300}`
        : 'none'};
    background-color: ${(props) =>
      props?.disabled
        ? props.theme.config.colors.gray_200
        : props.theme.config.colors.light};

    &::placeholder {
      color: ${(props) => props.theme.config.colors.gray_300};
    }

    &:-ms-input-placeholder {
      color: ${(props) => props.theme.config.colors.gray_300};
    }

    &::-ms-input-placeholder {
      color: ${(props) => props.theme.config.colors.gray_300};
    }
  }
`;

const FloatingPlaceholder = styled.div`
  position: absolute;
  left: 1.6rem;
  top: -0.7rem;
  z-index: 1;
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity 0.15s ease-in-out;

  span {
    position: relative;
    font-size: 1.2rem;
    line-height: 14px;
    color: ${(props) => props.theme.config.colors.gray_400};
    z-index: 1;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -0.1rem;
    width: 100%;
    height: 1rem;
    z-index: 0;
    background-color: ${(props) =>
      props?.isDisabled
        ? props.theme.config.colors.gray_200
        : props.theme.config.colors.light};
  }
`;
const RightIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 1.2rem;
  transform: translateY(-50%);
  cursor: ${(props) => (props.onClick ? 'pointer' : 'unset')};

  svg path {
    fill: ${(props) => props.theme.config.colors.gray_400};
    transition: all 0.15s ease-in-out;
  }

  &:hover {
    svg path {
      fill: ${(props) =>
        props.onClick
          ? props.theme.config.colors.primary
          : props.theme.config.colors.gray_400};
    }
  }
`;

export { InputControl, FloatingPlaceholder, RightIcon };
