import styled from 'styled-components';

export const Container = styled.div`
  height: 2.5rem;
  padding: 0 1rem;
  display: flex;
  align-items: center;
`;

export const Button = styled.button`
  cursor: pointer;
  border: none;
  background: transparent;
  outline: none;

  > svg {
    width: 1.4rem;
    opacity: 0.5;

    path {
      fill: ${(props) => props.theme.config.colors.text_base};
    }
  }

  & + button {
    margin-left: ${(props) => props.theme.config.spacing.spacing_2};
  }
`;
