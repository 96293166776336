import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';

import {
  Row,
  Col,
  Label,
  FormGroup,
  Button,
  InputControl,
} from 'modules/Core/Common';
import UserPasswordSchema from './PasswordSchema';

import * as S from './SidebarGeneralPassword.styles';

const SidebarGeneralPassword = ({ onSave, loading }) => {
  const { goBack } = useHistory();
  const formRef = useRef(null);

  return (
    <>
      <Formik
        initialValues={{
          password: '',
          password_confirmation: '',
        }}
        validationSchema={UserPasswordSchema}
        onSubmit={onSave}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <S.Form onSubmit={handleSubmit} ref={formRef}>
            <Row style={{ height: 'inherit' }}>
              <Col xs={12}>
                <FormGroup>
                  <Label htmlFor="password">New password</Label>

                  <InputControl
                    inputType="password"
                    inputID="password"
                    inputName="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={errors.password}
                    value={values.password}
                    touched={touched.password}
                    containerElement={formRef?.current}
                    hasStrenghtPassword
                    required
                    autoComplete="new-password"
                  />
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="password_confirmation">
                    Confirm password
                  </Label>

                  <InputControl
                    inputType="password"
                    inputID="password_confirmation"
                    inputName="password_confirmation"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={errors.password_confirmation}
                    value={values.password_confirmation}
                    touched={touched.password_confirmation}
                    containerElement={formRef?.current}
                    required
                    autoComplete="new-password"
                  />
                </FormGroup>
              </Col>
              <Col
                xs={12}
                style={{ alignSelf: 'flex-end', marginBottom: '3.4rem' }}
              >
                <S.FooterButtons>
                  <Button
                    className="btn-cancel"
                    type="button"
                    medium
                    onClick={() => goBack()}
                    disabled={loading}
                  >
                    Cancel
                  </Button>
                  <Button
                    medium
                    type="submit"
                    hasLoading={loading}
                    loading={loading}
                    disabled={loading}
                  >
                    Save Settings
                  </Button>
                </S.FooterButtons>
              </Col>
            </Row>
          </S.Form>
        )}
      </Formik>
    </>
  );
};

SidebarGeneralPassword.propTypes = {
  onSave: PropTypes.func,
  loading: PropTypes.bool,
};

export default SidebarGeneralPassword;
