import { createSlice } from '@reduxjs/toolkit';
import {
  request,
  generateCancelToken,
  cancelRequests,
  isCancel,
  ejectCancelInterceptor,
} from 'modules/Api/HttpClient';
import {
  ANSWER_PARTIAL_TEST_BY_ID_URL,
  ANSWER_PRACTICE_PARTIAL_TEST_BY_ID_URL,
  ANSWER_PARTIAL_TEST_SHOW_URL,
  ANSWER_PARTIAL_TEST_UPDATE_URL,
  ANSWER_PARTIAL_TEST_CUSTOM_UPDATE_URL,
  TEST_SUBMISSIONS_NORMALS_EXPORT_URL,
  TEST_SUBMISSIONS_CUSTOM_EXPORT_URL,
  TEST_SUBMISSIONS_CUSTOM_URL,
  EXERCISES_SHOW_NEXT_EXERCISE,
  EXERCISES_CAN_SEND_TO_OTHER_EVALUATOR,
  ANSWER_SHOW_PRACTICE_ANSWER_BY_ID_URL,
} from 'modules/Api/Routes';
import { formDataFromObj } from 'modules/Api/RequestData';
import {
  getSubmissionsBaseURLByTestType,
  getSubmissionsURLFiltersParamsByTestType,
  getAnswerRequestModel,
  getChangeEvaluatorRequestModel,
} from 'modules/Submissions/Utils';
import {
  getUTCCustomStartDate,
  getUTCDefaultEndDate,
} from 'modules/Utils/Date';

const getCategoryById = (categories, categoryId) => {
  if (!categories || !categories?.length || !categoryId) return null;

  return categories.find((category) => category.id === +categoryId);
};

const currentStartDate = getUTCCustomStartDate(7);
const currentEndDate = getUTCDefaultEndDate();

let cancelToken;

const initialState = {
  loading: false,
  loadingSubmissionQuestions: false,
  error: null,
  exportFilter: {
    link: '',
    testId: '',
    statusExercise: '',
    startDate: currentStartDate,
    endDate: currentEndDate,
    startEvaluatedDate: '',
    endEvaluatedDate: '',
    startScheduleDate: '',
    endScheduleDate: '',
    startSubmissionDate: currentStartDate,
    endSubmissionDate: currentEndDate,
    extension: 'csv',
    loading: false,
  },
  data: {
    page: 1,
    perPage: 10,
    search: '',
    evaluatorId: '',
    statusExercise: '',
    testId: '',
    groupId: '',
    testTag: '',
    esEvaluator: false,
    startDate: currentStartDate,
    endDate: currentEndDate,
    startEvaluatedDate: '',
    endEvaluatedDate: '',
    startScheduleDate: '',
    endScheduleDate: '',
    startSubmissionDate: currentStartDate,
    endSubmissionDate: currentEndDate,
    sort: {
      sortType: null,
      sortBy: null,
    },
  },
  answers: {
    page: 1,
    perPage: 10,
    category: null,
    categoryId: '',
    categoryTag: '',
    sort: {
      sortType: null,
      sortBy: null,
    },
  },
  answer: {},
  nextAnswerId: null,
  nextExercise: {
    loading: false,
    error: null,
    data: null,
  },
  previousAnswerId: null,
  previousExercise: null,
  grades: null,
  strengthsAndRecommendations: null,
  scoresByCategory: [],
  photos: [],
  videos: [],
  categories: [],
  student: {},
  evaluator: {},
  customTestCategoryId: null,
  customTestId: null,
  categoryId: null,
  testId: null,
  scheduleStatus: null,
  canSendToOtherEvaluator: false,
  isDeleting: false,
};

const submissionsSlice = createSlice({
  name: 'submissions',
  initialState,
  reducers: {
    cancelRequests: () => {
      cancelToken?.cancel();
      cancelRequests();
    },
    cleanState: () => ({ ...initialState }),
    /**
     * indicate that a request is started
     */
    changeSort: (state, action) => {
      const newSort = action.payload;
      state.data.sort.sortType =
        state.data.sort.sortBy === newSort && state.data.sort.sortType === 'ASC'
          ? 'DESC'
          : 'ASC';
      state.data.sort.sortBy = action.payload;
    },
    changeAnswersSort: (state, action) => {
      const newSort = action.payload;
      state.answers.sort.sortType =
        state.answers.sort.sortBy === newSort &&
        state.answers.sort.sortType === 'ASC'
          ? 'DESC'
          : 'ASC';
      state.answers.sort.sortBy = action.payload;
    },
    changeExportFilter: (state, action) => {
      state.exportFilter = {
        ...state.exportFilter,
        [action.payload?.param]: action.payload?.value,
      };
    },
    requestSubmissions: (state) => {
      state.loading = true;
      state.error = null;
    },
    requestSubmissionQuestions: (state) => {
      state.loadingSubmissionQuestions = true;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setIsDeleting: (state, action) => {
      state.isDeleting = action.payload;
    },
    /**
     * receive a success response
     */
    receiveRequestSuccess: (state) => {
      state.loading = false;
    },
    /**
     * receive a success submissions list response
     */
    receiveSubmissionsList: (state, action) => {
      state.loading = false;
      state.data = {
        ...state.data,
        submissions: action.payload?.exercises,
        totalCompleted: action.payload?.total_completed,
        totalExercises: action.payload?.total_items,
        totalPending: action.payload?.total_pending,
        total: action.payload?.total_items,
        urlExportCsv: action.payload?.csv,
        urlExportXls: action.payload?.xls,
      };
    },
    receiveSubmissionAnswers: (state, action) => {
      const practiceQuestions = action.payload?.questions?.map((question) => ({
        ...question,
        id: question.answer_id,
        isPractice: true,
      }));

      state.loading = false;
      state.student = {
        ...action.payload?.student,
      };
      state.answers = {
        ...state.answers,
        submissionAnswers:
          action.payload?.answers_partial_tests || practiceQuestions,
        total: action.payload?.total_items,
        category: state.answers.categoryId
          ? getCategoryById(
              action.payload?.categories?.length > 0
                ? action.payload?.categories
                : action.payload?.custom_test_categories,
              state.answers.categoryId
            )
          : null,
        deviceInfo: {
          ...action.payload?.device,
        },
        testType: action.payload?.custom_test || action.payload?.base_test,
        sections: action.payload?.sections,
        evaluatorPosition: action.payload?.evaluator_position,
        minimumEvaluation: action.payload?.minimum_evaluation,
      };
      state.categories =
        action.payload?.categories?.length > 0
          ? action.payload.categories
          : action.payload.custom_test_categories;
      state.categories.unshift({ id: 'all', name: 'All Categories' });
    },
    receiveSubmissionQuestions: (state, action) => {
      state.loading = false;
      state.loadingSubmissionQuestions = false;
      state.answer = action.payload?.answer_partial_test;
      state.answer.evaluatorPosition = action.payload?.evaluator_position;
      state.answer.minimumEvaluation = action.payload?.minimum_evaluation;
      state.nextAnswerId = action.payload?.next_answer_id;
      state.previousExercise = action.payload?.previous_exercise;
      state.previousAnswerId = action.payload?.previous_answer_id;
      state.grades = action.payload?.grades;
      state.strengthsAndRecommendations =
        typeof action.payload?.strengths_and_recommendations === 'object' &&
        !Array.isArray(action.payload?.strengths_and_recommendations)
          ? action.payload?.strengths_and_recommendations
          : null;
      state.scoresByCategory = action.payload?.scores_by_category;
      state.photos = action.payload?.photos;
      state.videos = action.payload?.videos;
      state.student = action.payload?.student;
      state.evaluator = action.payload?.evaluator;
      state.scheduleLinkEvaluator = action.payload?.schedule_link_evaluator;
      state.scheduleLinkStudent = action.payload?.schedule_link_student;
      state.canSendToOtherEvaluator =
        action.payload?.can_send_to_other_evaluator;
      state.evaluatorPosition = action.payload?.evaluator_position;
      state.customTestCategoryId = action.payload?.custom_test_category_id;
      state.customTestId = action.payload?.custom_test_id;
      state.categoryId = action.payload?.category_id;
      state.testId = action.payload?.test_id;
      state.scheduleStatus = action.payload?.schedule_status;
      state.answerPosition = action.payload?.answer_position;
      state.totalAnswersCompleted = action.payload?.total_answers_completed;
      state.totalAnswers = action.payload?.total_answers;
    },
    receiveSubmissionQuestionsUpdate: (state, action) => {
      state.loading = false;
      state.answerPosition = action.payload?.answer_position;
      state.totalAnswersCompleted = action.payload?.total_answers_completed;
      state.totalAnswers = action.payload?.total_answers;
    },
    receiveSubmissionPracticeAnswer: (state, action) => {
      state.loadingSubmissionQuestions = false;
      state.answer = action.payload?.practice_answer;
      state.customTestCategoryId = action.payload?.custom_test_category_id;
      state.customTestId = action.payload?.custom_test_id;
      state.categoryId = action.payload?.category_id;
      state.testId = action.payload?.test_id;
    },
    clearSubmissionsList: (state) => {
      state.loading = false;
      state.data = {
        page: 1,
        perPage: 10,
        sort: {
          sortType: null,
          sortBy: null,
        },
      };
    },
    clearSubmissionAnswers: (state) => {
      state.loading = false;
      state.answers = {
        page: 1,
        perPage: 10,
        category: null,
        sort: {
          sortType: null,
          sortBy: null,
        },
      };
      state.categories = [];
    },
    clearSubmissionQuestions: (state) => {
      state.loading = false;
      state.answer = {};
      state.grades = null;
      state.strengthsAndRecommendations = null;
      state.scoresByCategory = [];
      state.photos = [];
      state.videos = [];
    },
    changeSubmissionsPage: (state, action) => {
      state.data.page = action.payload;
    },
    changeSubmissionsPerPage: (state, action) => {
      state.data.perPage = action.payload;
      state.data.page = 1;
    },
    changeSubmissionsSearch: (state, action) => {
      state.data.page = 1;
      state.data.search = action.payload?.search || '';
    },
    changeSubmissionsEsEvaluator: (state, action) => {
      state.data.page = 1;
      state.data.esEvaluator = !!action.payload;
    },
    changeSubmissionsFilters: (state, action) => {
      state.data.search = action.payload?.search || '';
      state.data.page = isNaN(action?.payload?.page)
        ? 1
        : Number(action?.payload?.page);
      state.data.perPage = isNaN(action?.payload?.paginates_per)
        ? state.data.perPage
        : Number(action?.payload?.paginates_per);
      state.data.esEvaluator = !!action.payload?.es_evaluator;
      state.data.evaluatorId = action.payload?.evaluator_id || '';
      state.data.statusExercise = action.payload?.status_exercise || '';
      state.data.testId = action.payload?.test_id || '';
      state.data.groupId = action.payload?.group_id || '';
      state.data.testTag = action.payload?.test_tag || '';
      state.data.startDate = action.payload?.start_date;
      state.data.endDate = action.payload?.end_date;
      state.data.startEvaluatedDate = action.payload?.start_evaluated_date;
      state.data.endEvaluatedDate = action.payload?.end_evaluated_date;
      state.data.startScheduleDate = action.payload?.start_schedule_date;
      state.data.endScheduleDate = action.payload?.end_schedule_date;
      state.data.startSubmissionDate =
        action.payload?.start_submission_date || currentStartDate;
      state.data.endSubmissionDate =
        action.payload?.end_submission_date || currentEndDate;

      state.exportFilter.testId = action.payload?.test_id || '';
      state.exportFilter.statusExercise = action.payload?.status_exercise || '';
      state.exportFilter.evaluatorId = action.payload?.evaluator_id || '';
      state.exportFilter.startDate = action.payload?.start_date;
      state.exportFilter.endDate = action.payload?.end_date;
      state.exportFilter.startEvaluatedDate =
        action.payload?.start_evaluated_date;
      state.exportFilter.endEvaluatedDate = action.payload?.end_evaluated_date;
      state.exportFilter.startScheduleDate =
        action.payload?.start_schedule_date;
      state.exportFilter.endScheduleDate = action.payload?.end_schedule_date;
      state.exportFilter.startSubmissionDate =
        action.payload?.start_submission_date || currentStartDate;
      state.exportFilter.endSubmissionDate =
        action.payload?.end_submission_date || currentEndDate;
    },
    changeSubmissionAnswersPage: (state, action) => {
      state.answers.page = action.payload;
    },
    changeSubmissionAnswersPerPage: (state, action) => {
      state.answers.perPage = action.payload;
      state.answers.page = 1;
    },
    changeSubmissionAnswersCategory: (state, action) => {
      state.answers.categoryId = action.payload.id;
      state.answers.categoryTag = action.payload.tag;
      state.answers.page = 1;
    },
    updateSubmissionOnList: (state, action) => {
      const updSubmission = action.payload;
      const index = state.data?.submissions?.findIndex(
        (submission) => submission.id === updSubmission.id
      );
      if (index >= 0) state.data.submissions.splice(index, 1, updSubmission);
    },
    changeSubmissionAnswersComments: (state, action) => {
      state.answer.comments = action?.payload;
    },
    changeSubmissionAnswersEmptyAnswers: (state, action) => {
      state.answer.empty_answers = action?.payload;
    },
    changeSubmissionAnswersGrade: (state, action) => {
      const { grade, mark } = action.payload;

      state.grades = {
        ...state.grades,
        [grade]: {
          ...state.grades[grade],
          grade_mark: mark,
        },
      };
    },
    changeSubmissionAnswersStrength: (state, action) => {
      const { id, flag_active } = action.payload;

      state.strengthsAndRecommendations.strengths =
        state.strengthsAndRecommendations.strengths.map((strength) => {
          if (strength.id === id)
            return {
              ...strength,
              flag_active,
            };

          return strength;
        });
    },
    changeSubmissionAnswersRecommendation: (state, action) => {
      const { id, flag_active } = action.payload;

      state.strengthsAndRecommendations.recommendations =
        state.strengthsAndRecommendations.recommendations.map(
          (recommendation) => {
            if (recommendation.id === id)
              return {
                ...recommendation,
                flag_active,
              };

            return recommendation;
          }
        );
    },
    changeSubmissionAnswersMark: (state, action) => {
      state.answer.marks = action?.payload;
    },
    updateEvaluator: (state, action) => {
      state.evaluator = action?.payload;
    },
    /**
     * receive an error response
     */
    receiveSubmissionsError: (state, action) => {
      state.loading = false;
      state.loadingSubmissionQuestions = false;
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = '';
    },
    requestExportLinks: (state) => {
      state.exportFilter.link = '';
      state.exportFilter.loading = true;
    },
    receiveExportLinks: (state, action) => {
      state.exportFilter.loading = false;
      state.exportFilter.link =
        action?.payload?.[`link_download_${state.exportFilter.extension}`];
    },
    receiveExportLinksError: (state, action) => {
      state.exportFilter.loading = false;
      state.error = action.payload;
    },
    requestNextExercise: (state) => {
      state.nextExercise.loading = true;
      state.nextExercise.error = null;
      state.nextExercise.data = null;
    },
    requestCanSendToOtherEvaluator: (state) => {
      state.canSendToOtherEvaluatorError = null;
      state.canSendToOtherEvaluator = false;
    },
    receiveNextExercise: (state, action) => {
      state.nextExercise.loading = false;
      state.nextExercise.data = action.payload;
    },
    receiveCanSendToOtherEvaluator: (state, action) => {
      state.canSendToOtherEvaluator = action.payload;
    },
    receiveNextExerciseError: (state, action) => {
      state.nextExercise.loading = false;
      state.nextExercise.error = action.payload;
    },
    receiveCanSendToOtherEvaluatorError: (state, action) => {
      state.canSendToOtherEvaluatorError = action.payload;
    },
  },
});

const Actions = submissionsSlice.actions;

const Selectors = {
  fetchListData: (state) => state.submissions,
};

const Async = {
  fetchSubmissionsList:
    ({ testType }) =>
    async (dispatch, getState) => {
      const {
        submissions: {
          data: {
            page,
            perPage,
            search,
            evaluatorId,
            statusExercise,
            testId,
            groupId,
            testTag,
            startDate,
            endDate,
            startEvaluatedDate,
            endEvaluatedDate,
            startScheduleDate,
            endScheduleDate,
            startSubmissionDate,
            endSubmissionDate,
            esEvaluator,
            sort: { sortType, sortBy },
          },
        },
      } = getState();

      ejectCancelInterceptor();
      cancelToken?.cancel();
      cancelToken = generateCancelToken();

      let action;

      dispatch(Actions.requestSubmissions());

      const baseURL = getSubmissionsBaseURLByTestType(testType);
      const filterParams = getSubmissionsURLFiltersParamsByTestType({
        testType,
        evaluatorId,
        statusExercise,
        testId,
        groupId,
        testTag,
        esEvaluator,
      });

      let params = {
        page,
        paginates_per: perPage,
        search,
        sort: sortType,
        sort_by: sortBy,
        ...filterParams,
      };

      if (!['invalid', 'practice'].includes(testType)) {
        params = {
          ...params,
          start_evaluated_date: startEvaluatedDate,
          end_evaluated_date: endEvaluatedDate,
          start_schedule_date: startScheduleDate,
          end_schedule_date: endScheduleDate,
          start_submission_date: startSubmissionDate,
          end_submission_date: endSubmissionDate,
        };
      } else {
        params = {
          ...params,
          start_date: startDate,
          end_date: endDate,
        };
      }

      try {
        const response = await request({
          cancelToken: cancelToken.token,
          method: 'GET',
          url: `${baseURL}/list`,
          params,
        });

        action = Actions.receiveSubmissionsList(response.data.content);
      } catch (e) {
        if (!isCancel(e)) {
          action = Actions.receiveSubmissionsError(e.message);
        }
      }

      action && dispatch(action);
    },

  fetchSubmissionAnswers:
    ({ id, isCustomTest }) =>
    async (dispatch, getState) => {
      const {
        submissions: {
          answers: {
            page,
            perPage,
            categoryId,
            categoryTag,
            sort: { sortType, sortBy },
          },
        },
      } = getState();

      let action;

      dispatch(Actions.requestSubmissions());

      let categoryParams = {
        category_tag: categoryTag || undefined,
      };

      if (isCustomTest) {
        categoryParams.custom_test_category_id =
          categoryId !== 'all' ? categoryId : undefined;
      } else {
        categoryParams.category_id =
          categoryId !== 'all' ? categoryId : undefined;
      }

      try {
        const response = await request({
          method: 'GET',
          url: `${ANSWER_PARTIAL_TEST_BY_ID_URL}/${id}`,
          params: {
            id,
            page,
            paginates_per: perPage,
            sort: sortType,
            sort_by: sortBy,
            ...categoryParams,
          },
        });

        action = Actions.receiveSubmissionAnswers(response.data.content);
      } catch (e) {
        action = Actions.receiveSubmissionsError(e.message);
      }

      dispatch(action);
    },

  fetchPracticeSubmissionAnswers:
    ({ id, isCustomTest }) =>
    async (dispatch, getState) => {
      const {
        submissions: {
          answers: {
            page,
            perPage,
            categoryId,
            categoryTag,
            sort: { sortType, sortBy },
          },
        },
      } = getState();

      let action;

      dispatch(Actions.requestSubmissions());

      let categoryParams = {
        category_tag: categoryTag || undefined,
      };

      if (isCustomTest) {
        categoryParams.custom_test_category_id =
          categoryId !== 'all' ? categoryId : undefined;
      } else {
        categoryParams.category_id =
          categoryId !== 'all' ? categoryId : undefined;
      }

      try {
        const response = await request({
          method: 'GET',
          url: ANSWER_PRACTICE_PARTIAL_TEST_BY_ID_URL,
          params: {
            exercise_id: id,
            page,
            paginates_per: perPage,
            sort: sortType,
            sort_by: sortBy,
            ...categoryParams,
          },
        });

        action = Actions.receiveSubmissionAnswers(response.data.content);
      } catch (e) {
        action = Actions.receiveSubmissionsError(e.message);
      }

      dispatch(action);
    },

  fetchSubmissionAnswer:
    ({ id }) =>
    async (dispatch) => {
      let action;

      dispatch(Actions.requestSubmissionQuestions());

      try {
        const response = await request({
          method: 'GET',
          url: `${ANSWER_PARTIAL_TEST_SHOW_URL}/${id}`,
        });

        action = Actions.receiveSubmissionQuestions(response.data.content);
      } catch (e) {
        action = Actions.receiveSubmissionsError(e.message);
      }

      dispatch(action);
    },

  fetchSubmissionPracticeAnswer:
    ({ id }) =>
    async (dispatch) => {
      let action;

      dispatch(Actions.requestSubmissionQuestions());

      try {
        const response = await request({
          method: 'GET',
          url: ANSWER_SHOW_PRACTICE_ANSWER_BY_ID_URL,
          params: {
            id,
          },
        });

        action = Actions.receiveSubmissionPracticeAnswer(response.data.content);
      } catch (e) {
        action = Actions.receiveSubmissionsError(e.message);
      }

      dispatch(action);
    },

  updateAnswer:
    ({ testType, onSuccess, onError }) =>
    async (dispatch, getState) => {
      dispatch(Actions.setLoading(true));

      const {
        submissions: { answer, grades, strengthsAndRecommendations },
      } = getState();

      const isCustomTest = [
        answer?.test_type?.toLowerCase(),
        testType,
      ].includes('custom');

      const requestData = getAnswerRequestModel(
        {
          ...answer,
          grades,
          strengthsAndRecommendations,
        },
        isCustomTest
      );
      const formData = formDataFromObj(requestData);

      try {
        const response = await request({
          method: 'PUT',
          url: `${
            isCustomTest
              ? ANSWER_PARTIAL_TEST_CUSTOM_UPDATE_URL
              : ANSWER_PARTIAL_TEST_UPDATE_URL
          }/${answer?.id}`,
          data: formData,
        });

        dispatch(
          Actions.receiveSubmissionQuestionsUpdate(response?.data?.content)
        );
        onSuccess(response);
      } catch (e) {
        dispatch(Actions.setLoading(false));
        onError(e);
      }
    },

  deleteSubmission:
    ({ testType, id, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.setIsDeleting(true));

      try {
        const baseURL = getSubmissionsBaseURLByTestType(testType);
        const response = await request({
          method: 'DELETE',
          url: `${baseURL}/delete?id=${id}`,
        });

        onSuccess(response);
        dispatch(Actions.setIsDeleting(false));
      } catch (e) {
        onError(e);
        dispatch(Actions.setIsDeleting(false));
      }
    },

  cancelScheduledInterview:
    ({ testType, id, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.setLoading(true));

      try {
        const baseURL = getSubmissionsBaseURLByTestType(testType);
        const data = formDataFromObj({ id });

        const response = await request({
          method: 'POST',
          url: `${baseURL}/cancel_interview`,
          data,
        });

        onSuccess(response);
      } catch (e) {
        onError(e);
      } finally {
        dispatch(Actions.setLoading(false));
      }
    },

  changeEvaluator:
    ({ testType, data, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.setLoading(true));

      try {
        const baseURL = getSubmissionsBaseURLByTestType(testType);
        const changeEvaluatorRequestModel =
          getChangeEvaluatorRequestModel(data);
        const changeEvaluatorData = formDataFromObj(
          changeEvaluatorRequestModel
        );

        const response = await request({
          method: 'PUT',
          url: `${baseURL}/change_evaluator`,
          data: changeEvaluatorData,
        });

        onSuccess(response);
      } catch (e) {
        onError(e);
      } finally {
        dispatch(Actions.setLoading(false));
      }
    },

  autoChangeEvaluator:
    ({ id, onSuccess, onError }) =>
    async (dispatch) => {
      dispatch(Actions.setLoading(true));

      try {
        const response = await request({
          method: 'POST',
          url: `${TEST_SUBMISSIONS_CUSTOM_URL}/send_to_another_evaluator`,
          data: {
            id,
          },
        });

        onSuccess(response);
      } catch (e) {
        onError(e);
      } finally {
        dispatch(Actions.setLoading(false));
      }
    },

  fetchExportSubmitBase:
    ({ onSuccess, onError }) =>
    async (dispatch, getState) => {
      const {
        submissions: {
          data: { esEvaluator, search },
          exportFilter: {
            testId,
            evaluatorId,
            statusExercise,
            startEvaluatedDate,
            endEvaluatedDate,
            startScheduleDate,
            endScheduleDate,
            startSubmissionDate,
            endSubmissionDate,
            extension,
          },
        },
      } = getState();
      let action;

      dispatch(Actions.requestExportLinks());

      const data = {
        status_exercise: statusExercise === 'Completed' ? true : false,
        es_evaluator: esEvaluator,
        file_type: extension,
        start_evaluated_date: startEvaluatedDate,
        end_evaluated_date: endEvaluatedDate,
        start_schedule_date: startScheduleDate,
        end_schedule_date: endScheduleDate,
        start_submission_date: startSubmissionDate,
        end_submission_date: endSubmissionDate,
        search,
      };

      testId && (data.test_id = testId);
      evaluatorId && (data.evaluator_id = evaluatorId);

      try {
        const response = await request({
          method: 'POST',
          url: TEST_SUBMISSIONS_NORMALS_EXPORT_URL,
          data,
        });

        action = Actions.receiveExportLinks(response.data.content);
        onSuccess(response.data.content);
      } catch (e) {
        action = Actions.receiveExportLinksError(e.message);
        onError();
      }

      dispatch(action);
    },

  fetchExportSubmitCustom:
    ({ onSuccess, onError }) =>
    async (dispatch, getState) => {
      const {
        submissions: {
          data: { search },
          exportFilter: {
            testId,
            statusExercise,
            evaluatorId,
            startEvaluatedDate,
            endEvaluatedDate,
            startScheduleDate,
            endScheduleDate,
            startSubmissionDate,
            endSubmissionDate,
            extension,
          },
        },
      } = getState();
      let action;

      dispatch(Actions.requestExportLinks());

      const formattedStatus = {
        schedule_status: ['Scheduled', 'Missed', 'Canceled', 'Done'].includes(
          statusExercise
        )
          ? statusExercise?.toUpperCase()
          : null,
        status_exercise:
          statusExercise === 'Pending'
            ? 0
            : statusExercise === 'Completed'
            ? 1
            : statusExercise === 'Skipped'
            ? 2
            : null,
      };

      const data = {
        file_type: extension,
        start_evaluated_date: startEvaluatedDate,
        end_evaluated_date: endEvaluatedDate,
        start_schedule_date: startScheduleDate,
        end_schedule_date: endScheduleDate,
        start_submission_date: startSubmissionDate,
        end_submission_date: endSubmissionDate,
        search,
      };

      testId && (data.custom_test_id = testId);
      evaluatorId && (data.evaluator_id = evaluatorId);
      formattedStatus.schedule_status &&
        (data.schedule_status = formattedStatus.schedule_status);
      formattedStatus.status_exercise !== null &&
        (data.status_exercise = formattedStatus.status_exercise);

      try {
        const response = await request({
          method: 'POST',
          url: TEST_SUBMISSIONS_CUSTOM_EXPORT_URL,
          data,
        });

        action = Actions.receiveExportLinks(response.data.content);
        onSuccess(response.data.content);
      } catch (e) {
        action = Actions.receiveExportLinksError(e.message);
        onError();
      }

      dispatch(action);
    },

  fetchNextExercise: (params) => async (dispatch) => {
    let action;
    let sendAction;

    dispatch(Actions.requestNextExercise());
    dispatch(Actions.requestCanSendToOtherEvaluator());

    try {
      const response = await request({
        method: 'GET',
        url: `${EXERCISES_SHOW_NEXT_EXERCISE}`,
        params,
      });
      const sendResponse = await request({
        method: 'GET',
        url: `${EXERCISES_CAN_SEND_TO_OTHER_EVALUATOR}`,
        params: { id: params.id },
      });

      action = Actions.receiveNextExercise(response.data.content);
      sendAction = Actions.receiveCanSendToOtherEvaluator(
        sendResponse.data.content
      );
    } catch (e) {
      action = Actions.receiveNextExerciseError(e.message);
      sendAction = Actions.receiveNextExerciseError(e.message);
    }

    dispatch(action);
    dispatch(sendAction);
  },
};

const { reducer } = submissionsSlice;

export { reducer, Actions, Async, Selectors };
