import { createSlice } from '@reduxjs/toolkit';
import {
  request,
  generateCancelToken,
  cancelRequests,
  isCancel,
  ejectCancelInterceptor,
} from 'modules/Api/HttpClient';
import {
  PRACTICE_RESULTS_INSTITUTIONS_URL,
  PRACTICE_RESULTS_INSTITUTIONS_SHOW_URL,
  PRACTICE_RESULTS_INSTITUTIONS_HISTORY_URL,
  PRACTICE_RESULTS_HISTORIES_URL,
  PRACTICE_RESULTS_INSTITUTIONS_ANALYTICS_URL,
  PRACTICE_RESULTS_STUDENTS_URL,
  PRACTICE_RESULTS_STUDENTS_DETAILS_URL,
  GROUPS_URL,
  UNITS_BY_GROUP_URL,
  SCHOOLS_BY_UNIT_URL,
  CLASSES_BY_SCHOOL_URL,
  BASE_TESTS_URL,
  CATEGORIES_URL,
} from 'modules/Api/Routes';
import { defaultRequest } from 'modules/Utils/DefaultRequest';
import { getISOStringFromStringDate } from 'modules/Utils';
import { formatAdvancedParams, getApiUrlByScreen } from './Utils';
import {
  getUTCDefaultStartDate,
  getUTCDefaultEndDate,
} from 'modules/Utils/Date';
import { ConvertPlacementToProgressCheck } from 'modules/Utils';

const startDate = getUTCDefaultStartDate();
const endDate = getUTCDefaultEndDate();

let cancelToken;

const initialState = {
  loading: false,
  error: '',
  data: {
    page: 1,
    perPage: 10,
    link_download_csv: '',
    link_download_xls: '',
    total: 0,
    search: '',
    groupId: '',
    unitId: '',
    schoolId: '',
    classId: '',
    testId: '',
    categoryId: '',
    studentId: '',
    endDate: endDate,
    startDate: startDate,
    sort: {
      sortType: null,
      sortBy: null,
    },
  },
  groups: {
    data: [],
    loading: false,
  },
  units: {
    data: [],
    loading: false,
  },
  schools: {
    data: [],
    loading: false,
  },
  classes: {
    data: [],
    loading: false,
  },
  tests: {
    data: [],
    loading: false,
  },
  categories: {
    data: [],
    loading: false,
  },
  rowInformation: {
    data: null,
    loading: false,
  },
  singleTest: {
    data: {},
  },
  analytics: {
    data: {},
    loading: false,
  },
  histories: {
    data: [],
    loading: false,
  },
  exportFilter: {
    data: {
      tests: {
        data: [],
        loading: false,
      },
      categories: {
        data: [],
        loading: false,
      },
    },
    resultsScreen: '',
    testId: '',
    categoryTag: '',
    startDate: startDate,
    endDate: endDate,
    extension: 'csv',
    link: '',
    loading: false,
  },
};

const practiceResultsSlice = createSlice({
  name: 'practiceResults',
  initialState,
  reducers: {
    cancelRequests: () => {
      cancelToken?.cancel();
      cancelRequests();
    },
    cleanState: () => ({ ...initialState }),
    /**
     * indicate that a request is started
     */
    changeSort: (state, action) => {
      const newSort = action.payload;
      state.data.sort.sortType =
        state.data.sort.sortBy === newSort && state.data.sort.sortType === 'ASC'
          ? 'DESC'
          : 'ASC';
      state.data.sort.sortBy = action.payload;
    },
    changeExportFilter: (state, action) => {
      state.exportFilter = {
        ...state.exportFilter,
        [action.payload?.param]: action.payload?.value,
      };

      if (action.payload?.param === 'testId') {
        state.exportFilter.categoryTag = '';
      }

      if (
        action.payload?.param === 'testId' &&
        action.payload?.value === 'all'
      ) {
        state.exportFilter.categoryId = 'all';
      }
    },
    changePerPage: (state, action) => {
      state.data.perPage = action.payload;
      state.data.page = 1;
    },
    requestPracticeResults: (state) => {
      state.loading = true;
      state.error = '';
    },
    requestRowInformation: (state) => {
      state.rowInformation.loading = true;
      state.rowInformation.data = null;
    },
    requestPracticeResultsAnalytics: (state) => {
      state.analytics.loading = true;
    },
    requestPracticeHistories: (state) => {
      state.histories.loading = true;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    /**
     * receive a success response
     */
    receiveRequestSuccess: (state) => {
      state.loading = false;
    },
    /**
     * receive a success practiceResults list response
     */
    receivePracticeResultsList: (state, action) => {
      const resultsType = `practice_results_${
        action?.payload?.resultsType === 'student'
          ? action?.payload?.resultsType
          : 'institutions'
      }`;

      state.loading = false;
      state.data = {
        ...state.data,
        practiceResults: action?.payload?.content?.[resultsType],
        link_download_csv: action?.payload?.content?.link_download_csv,
        link_download_xls: action?.payload?.content?.link_download_xls,
        total: action?.payload?.content?.total_items,
      };
    },

    receiveRowInformation: (state, action) => {
      state.rowInformation.loading = false;
      state.rowInformation.data = action?.payload?.practice_results_institution;
    },

    receivePracticeResultsAnalytics: (state, action) => {
      state.analytics.loading = false;
      state.analytics.data = action.payload;
    },

    receivePracticeHistories: (state, action) => {
      state.histories.loading = false;
      state.histories.data = action.payload.histories;
    },

    receivePracticeResultsHistories: (state, action) => {
      state.analytics.loading = false;
      state.analytics.data = {
        ...state.analytics.data,
        histories: action.payload.histories,
      };
    },

    clearPracticeResultsList: (state) => {
      state.data = {
        ...state.data,
        practiceResults: [],
        page: 1,
        perPage: 10,
        link_download_csv: '',
        link_download_xls: '',
        total: 0,
        search: '',
        groupId: '',
        unitId: '',
        schoolId: '',
        classId: '',
        testId: '',
        categoryId: '',
        studentId: '',
        endDate,
        startDate,
        sort: {
          sortType: null,
          sortBy: null,
        },
      };
      state.categories = {
        data: [],
        loading: false,
      };
    },

    resetPage: (state) => {
      state.data.page = 1;
    },
    /**
     * receive an error response
     */
    receivePracticeResultsError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    receivePracticeResultsAnalyticsError: (state, action) => {
      state.analytics.loading = false;
      state.analytics.error = action.payload;
    },

    changePracticeResultsPage: (state, action) => {
      state.data.page = action.payload;
    },

    changePracticeResultsFilters: (state, action) => {
      state.data.page = isNaN(action?.payload?.page)
        ? 1
        : Number(action?.payload?.page);
      state.data.perPage = isNaN(action?.payload?.paginates_per)
        ? state.data.perPage
        : Number(action?.payload?.paginates_per);
      state.data.groupId = action?.payload?.group_id || null;
      state.data.unitId = action?.payload?.unit_id || null;
      state.data.schoolId = action?.payload?.school_id || null;
      state.data.classId = action?.payload?.class_id || null;
      state.data.testId = action?.payload?.test_id || null;
      state.data.categoryId = action?.payload?.category_id || null;
      state.data.studentId = action?.payload?.studentId || null;
      state.data.search = action?.payload?.search || '';
      state.data.startDate = action.payload?.start_date || state.data.startDate;
      state.data.endDate = action.payload?.end_date || state.data.endDate;

      state.exportFilter.testId = action?.payload?.test_id || '';
      state.exportFilter.startDate =
        action.payload?.start_date || state.data.startDate;
      state.exportFilter.endDate =
        action.payload?.end_date || state.data.endDate;
    },

    requestGroups: (state) => {
      state.groups = {
        ...state.groups,
        loading: true,
      };
    },

    receiveGroupsError: (state, action) => {
      state.groups = {
        ...state.groups,
        loading: false,
      };
      state.error = action.payload;
    },

    receiveGroupsList: (state, action) => {
      state.groups = {
        ...state.groups,
        loading: false,
        data: action.payload.content.groups,
      };
    },

    requestUnits: (state) => {
      state.units = {
        ...state.units,
        loading: true,
      };
    },

    receiveUnitsError: (state, action) => {
      state.units = {
        ...state.units,
        loading: false,
      };
      state.error = action.payload;
    },

    receiveUnitsList: (state, action) => {
      state.units = {
        ...state.units,
        loading: false,
        data: action.payload.content,
      };
    },

    requestSchools: (state) => {
      state.schools = {
        ...state.schools,
        loading: true,
      };
    },

    receiveSchoolsError: (state, action) => {
      state.schools = {
        ...state.schools,
        loading: false,
      };
      state.error = action.payload;
    },

    receiveSchoolsList: (state, action) => {
      state.schools = {
        ...state.schools,
        loading: false,
        data: action.payload?.content?.schools,
      };
    },

    requestClasses: (state) => {
      state.classes = {
        ...state.classes,
        loading: true,
      };
    },

    receiveClassesError: (state, action) => {
      state.classes = {
        ...state.classes,
        loading: false,
      };
      state.error = action.payload;
    },

    receiveClassesList: (state, action) => {
      state.classes = {
        ...state.classes,
        loading: false,
        data: action.payload.content,
      };
    },

    clearError: (state) => {
      state.error = '';
    },

    requestPracticeTests: (state) => {
      state.tests = {
        ...state.tests,
        data: [],
        loading: true,
      };
      state.exportFilter.data.tests = {
        data: [],
        loading: true,
      };
    },

    receivePracticeTestsList: (state, action) => {
      state.tests = {
        ...state.tests,
        loading: false,
        data: ConvertPlacementToProgressCheck(action.payload.tests, true),
      };
      state.exportFilter.data.tests = {
        data: ConvertPlacementToProgressCheck(action.payload.tests, true),
        loading: false,
      };
    },

    receiveTestsError: (state, action) => {
      state.tests = {
        ...state.tests,
        loading: false,
        data: [],
      };
      state.exportFilter.data.tests = {
        data: [],
        loading: false,
      };
      state.error = action.payload;
    },

    changeResultFilters: (state, action) => {
      state.data.page = 1;
      state.data.search = action.payload.search;
      state.data.startDate = action.payload.startDate || state.data.startDate;
      state.data.endDate = action.payload.endDate || state.data.endDate;
    },

    requestExportCategories: (state) => {
      state.exportFilter.data.categories = {
        data: [],
        loading: true,
      };
    },

    receiveExportCategories: (state, action) => {
      const formatCategories = (array) =>
        array?.map(({ tag }) => ({
          id: tag,
          name: tag.replace('_', ' '),
          tag,
        }));
      state.exportFilter.data.categories = {
        ...state.exportFilter.data.categories,
        loading: false,
        data: formatCategories(action.payload?.categories),
      };
    },

    receiveExportCategoriesError: (state, action) => {
      state.exportFilter.data.categories = {
        ...state.exportFilter.data.categories,
        loading: false,
      };
      state.error = action.payload;
    },

    requestExportLinks: (state) => {
      state.exportFilter.link = '';
      state.exportFilter.loading = true;
    },

    receiveExportLinks: (state, action) => {
      state.exportFilter.link =
        action?.payload?.[`link_download_${state.exportFilter.extension}`];
      state.exportFilter.loading = false;
    },

    receiveExportLinksError: (state, action) => {
      state.exportFilter.loading = false;
      state.error = action.payload;
    },

    requestCategories: (state) => {
      state.categories = {
        ...state.categories,
        data: [],
        loading: true,
      };
      state.exportFilter.data.categories = {
        data: [],
        loading: true,
      };
    },

    receiveCategoriesList: (state, action) => {
      state.categories = {
        ...state.categories,
        loading: false,
        data: action.payload.categories.map((category) => ({
          id: category.tag,
          name: category.tag,
        })),
      };
      state.exportFilter.data.categories = {
        data: action.payload.categories,
        loading: false,
      };
    },

    receiveCategoriesError: (state, action) => {
      state.categories = {
        ...state.categories,
        loading: false,
        data: [],
      };
      state.exportFilter.data.categories = {
        data: [],
        loading: false,
      };
      state.error = action.payload;
    },
  },
});

const Actions = practiceResultsSlice.actions;

const Selectors = {
  fetchListData: (state) => state.practiceResults,
  practiceResultsLoading: ({ practiceResults: { loading } }) => ({ loading }),
};

const Async = {
  fetchPracticeResults: (resultsType) => async (dispatch, getState) => {
    const {
      practiceResults: {
        data: {
          page,
          perPage,
          groupId,
          unitId,
          schoolId,
          classId,
          testId,
          categoryId,
          search,
          startDate,
          endDate,
          sort: { sortType, sortBy },
        },
      },
    } = getState();

    if (!testId) return;

    ejectCancelInterceptor();
    cancelToken?.cancel();
    cancelToken = generateCancelToken();

    let action;

    dispatch(Actions.requestPracticeResults());

    try {
      const response = await request({
        cancelToken: cancelToken.token,
        method: 'GET',
        url: PRACTICE_RESULTS_INSTITUTIONS_URL,
        params: {
          search,
          group_id: groupId,
          unit_id: unitId,
          school_id: schoolId,
          class_id: classId,
          test_id: [null, 'all'].includes(testId) ? null : testId,
          category_tag: [null, 'all'].includes(categoryId) ? null : categoryId,
          start_date: getISOStringFromStringDate(startDate),
          end_date: getISOStringFromStringDate(endDate),
          page,
          paginates_per: perPage,
          sort: sortType,
          sort_by: sortBy,
        },
      });

      action = Actions.receivePracticeResultsList({
        content: response.data.content,
        resultsType,
      });
    } catch (e) {
      if (!isCancel(e)) {
        action = Actions.receivePracticeResultsError(e.message);
      }
    }

    action && dispatch(action);
  },

  fetchRowInformation: (student_id) => async (dispatch, getState) => {
    const {
      practiceResults: {
        data: { testId, startDate, endDate },
      },
    } = getState();

    let action;

    dispatch(Actions.requestRowInformation());

    try {
      const response = await request({
        method: 'GET',
        url: PRACTICE_RESULTS_INSTITUTIONS_SHOW_URL,
        params: {
          student_id,
          test_id: testId === 'all' ? null : testId,
          start_date: getISOStringFromStringDate(startDate),
          end_date: getISOStringFromStringDate(endDate),
        },
      });

      action = Actions.receiveRowInformation(response.data.content);
    } catch (e) {
      action = Actions.receivePracticeResultsError(e.message);
    }

    dispatch(action);
  },

  fetchPracticeResultsStudents: (resultsType) => async (dispatch, getState) => {
    const {
      practiceResults: {
        data: {
          page,
          perPage,
          testId,
          search,
          startDate,
          endDate,
          sort: { sortType, sortBy },
        },
      },
    } = getState();

    if (!testId) return;

    ejectCancelInterceptor();
    cancelToken?.cancel();
    cancelToken = generateCancelToken();

    let action;

    dispatch(Actions.requestPracticeResults());

    try {
      const response = await request({
        cancelToken: cancelToken.token,
        method: 'GET',
        url: PRACTICE_RESULTS_STUDENTS_URL,
        params: {
          search,
          test_id: testId === 'all' ? null : testId,
          start_date: getISOStringFromStringDate(startDate),
          end_date: getISOStringFromStringDate(endDate),
          page,
          paginates_per: perPage,
          sort: sortType,
          sort_by: sortBy,
        },
      });

      action = Actions.receivePracticeResultsList({
        content: response.data.content,
        resultsType,
      });
    } catch (e) {
      if (!isCancel(e)) {
        action = Actions.receivePracticeResultsError(e.message);
      }
    }

    action && dispatch(action);
  },

  fetchPracticeResultsByStudent: () => async (dispatch, getState) => {
    const {
      practiceResults: {
        data: { studentId, testId, categoryId, startDate, endDate },
      },
    } = getState();

    let action;

    dispatch(Actions.requestPracticeResultsAnalytics());

    try {
      const response = await request({
        method: 'GET',
        url: PRACTICE_RESULTS_STUDENTS_DETAILS_URL,
        params: {
          student_id: studentId,
          test_ids: testId === 'all' ? null : testId,
          category_tag: [null, 'all'].includes(categoryId) ? null : categoryId,
          start_date: getISOStringFromStringDate(startDate),
          end_date: getISOStringFromStringDate(endDate),
        },
      });

      action = Actions.receivePracticeResultsAnalytics({
        ...response.data,
      });
    } catch (e) {
      action = Actions.receivePracticeResultsAnalyticsError(e.message);
    }

    dispatch(action);

    dispatch(Actions.requestPracticeHistories());

    try {
      const response = await request({
        method: 'GET',
        url: PRACTICE_RESULTS_HISTORIES_URL,
        params: {
          student_id: studentId,
          test_id: testId === 'all' ? null : testId,
          category_tag: [null, 'all'].includes(categoryId) ? null : categoryId,
          start_date: getISOStringFromStringDate(startDate),
          end_date: getISOStringFromStringDate(endDate),
        },
      });

      action = Actions.receivePracticeHistories({
        histories: response.data.content.histories || [],
      });
    } catch (e) {
      action = Actions.receivePracticeResultsAnalyticsError(e.message);
    }

    dispatch(action);
  },

  fetchStudentHistory:
    ({ id, exerciseId } = {}) =>
    async (dispatch, getState) => {
      const {
        practiceResults: {
          data: { testId, categoryId, startDate, endDate },
        },
      } = getState();
      let action;

      dispatch(Actions.requestPracticeResultsAnalytics());

      const params = {
        id,
        exercise_id: exerciseId,
      };

      try {
        const response = await request({
          method: 'GET',
          url: `${PRACTICE_RESULTS_INSTITUTIONS_HISTORY_URL}/${id}/${exerciseId}`,
          params: params,
        });

        action = Actions.receivePracticeResultsAnalytics({
          ...response.data,
          histories: null,
        });
      } catch (e) {
        action = Actions.receivePracticeResultsAnalyticsError(e.message);
      }

      dispatch(action);

      dispatch(Actions.requestPracticeHistories());

      try {
        const response = await request({
          method: 'GET',
          url: PRACTICE_RESULTS_HISTORIES_URL,
          params: {
            student_id: id,
            test_id: testId === 'all' ? null : testId,
            category_tag: [null, 'all'].includes(categoryId)
              ? null
              : categoryId,
            start_date: getISOStringFromStringDate(startDate),
            end_date: getISOStringFromStringDate(endDate),
          },
        });

        action = Actions.receivePracticeHistories({
          histories: response.data.content.histories || [],
        });
      } catch (e) {
        action = Actions.receivePracticeResultsAnalyticsError(e.message);
      }

      dispatch(action);
    },

  fetchPracticeResultsAnalytics: () => async (dispatch, getState) => {
    const {
      practiceResults: {
        data: {
          groupId,
          unitId,
          schoolId,
          classId,
          testId,
          categoryId,
          startDate,
          endDate,
        },
      },
    } = getState();

    let action;

    dispatch(Actions.requestPracticeResultsAnalytics());

    try {
      const response = await request({
        method: 'GET',
        url: PRACTICE_RESULTS_INSTITUTIONS_ANALYTICS_URL,
        params: {
          group_id: groupId,
          unit_id: unitId,
          school_id: schoolId,
          class_id: classId,
          test_id: testId === 'all' ? null : testId,
          category_tag: [null, 'all'].includes(categoryId) ? null : categoryId,
          start_date: getISOStringFromStringDate(startDate),
          end_date: getISOStringFromStringDate(endDate),
        },
      });

      action = Actions.receivePracticeResultsAnalytics(
        response.data.content.practice_analytics
      );
    } catch (e) {
      action = Actions.receivePracticeResultsError(e.message);
    }

    dispatch(action);
  },

  fetchGroupsList: () => async (dispatch) =>
    defaultRequest(
      dispatch,
      GROUPS_URL,
      'GET',
      Actions.requestGroups,
      Actions.receiveGroupsList,
      Actions.receiveGroupsError
    ),

  fetchUnitsByGroupList:
    ({ group_id }) =>
    async (dispatch) =>
      defaultRequest(
        dispatch,
        `${UNITS_BY_GROUP_URL}?group_id=${group_id}`,
        'GET',
        Actions.requestUnits,
        Actions.receiveUnitsList,
        Actions.receiveUnitsError
      ),

  fetchSchoolsByUnitList:
    ({ unit_id }) =>
    async (dispatch) =>
      defaultRequest(
        dispatch,
        `${SCHOOLS_BY_UNIT_URL}?unit_id=${unit_id}`,
        'GET',
        Actions.requestSchools,
        Actions.receiveSchoolsList,
        Actions.receiveSchoolsError
      ),

  fetchClassesBySchoolList:
    ({ school_id }) =>
    async (dispatch) =>
      defaultRequest(
        dispatch,
        `${CLASSES_BY_SCHOOL_URL}?school_id=${school_id}`,
        'GET',
        Actions.requestClasses,
        Actions.receiveClassesList,
        Actions.receiveClassesError
      ),

  fetchPracticeTests: () => async (dispatch) => {
    let action;

    dispatch(Actions.requestPracticeTests());

    try {
      const response = await request({
        method: 'GET',
        url: BASE_TESTS_URL,
        params: {
          sort: 'ASC',
          sort_by: 'name',
        },
      });

      action = Actions.receivePracticeTestsList(response.data.content);
    } catch (e) {
      action = Actions.receiveTestsError(e.message);
    }

    dispatch(action);
  },

  fetchExportTestCategories: () => async (dispatch, getState) => {
    const {
      practiceResults: {
        exportFilter: { testId },
      },
    } = getState();
    let action;

    dispatch(Actions.requestExportCategories());

    try {
      const response = await request({
        method: 'GET',
        url: CATEGORIES_URL,
        params: {
          test_id: ['all']?.includes(testId) ? '' : testId,
        },
      });

      action = Actions.receiveExportCategories(response.data.content);
    } catch (e) {
      action = Actions.receiveExportCategoriesError(e.message);
    }

    dispatch(action);
  },

  fetchExportSubmit:
    ({ onSuccess, onError }) =>
    async (dispatch, getState) => {
      const {
        practiceResults: {
          data: { studentId, search, groupId, unitId, schoolId, classId },
          exportFilter,
        },
      } = getState();
      let action;

      dispatch(Actions.requestExportLinks());

      const url = getApiUrlByScreen(exportFilter?.resultsScreen);

      const params = {
        file_type: exportFilter?.extension,
        start_date: getISOStringFromStringDate(exportFilter?.startDate),
        end_date: getISOStringFromStringDate(exportFilter?.endDate),
        search,
        invalid_sessions_only: exportFilter?.invalid_sessions_only,
        no_sessions_only: exportFilter?.no_sessions_only,
      };

      if (studentId) params.id = studentId;

      if (exportFilter?.resultsScreen?.includes('institutions')) {
        params.group_id = groupId;
        params.unit_id = unitId;
        params.school_id = schoolId;
        params.class_id = classId;
      }

      let advancedParams = {};

      exportFilter?.testId !== 'all' && (params.test_id = exportFilter?.testId);

      if (exportFilter?.resultsScreen !== 'students-list') {
        exportFilter?.categoryTag &&
          exportFilter?.categoryTag !== 'all' &&
          (params.category_tag = exportFilter?.categoryTag);

        advancedParams = {
          ...formatAdvancedParams(exportFilter),
        };
      }

      try {
        const response = await request({
          method: 'POST',
          url,
          data: {
            ...params,
            ...advancedParams,
          },
        });

        action = Actions.receiveExportLinks(response.data.content);
        onSuccess(response.data.content);
      } catch (e) {
        action = Actions.receiveExportLinksError(e.message);
        onError();
      }

      dispatch(action);
    },

  fetchCategories:
    ({ test_id } = {}) =>
    async (dispatch) => {
      let action;

      dispatch(Actions.requestCategories());

      try {
        const response = await request({
          method: 'GET',
          url: CATEGORIES_URL,
          params: {
            sort: 'ASC',
            sort_by: 'name',
            test_id: test_id === 'all' ? null : test_id,
          },
        });

        action = Actions.receiveCategoriesList(response.data.content);
      } catch (e) {
        action = Actions.receiveCategoriesError(e.message);
      }

      dispatch(action);
    },
};

const { reducer } = practiceResultsSlice;

export { reducer, Actions, Async, Selectors };
