import { createSlice } from '@reduxjs/toolkit';
import {
  request,
  generateCancelToken,
  cancelRequests,
  isCancel,
  ejectCancelInterceptor,
} from 'modules/Api/HttpClient';
import { BASE_TESTS_URL, BASE_TEST_BY_ID_URL } from 'modules/Api/Routes';
import { ConvertPlacementToProgressCheck } from 'modules/Utils';

let cancelToken;

const initialState = {
  loading: false,
  error: null,
  data: {
    baseTests: [],
    page: 1,
    perPage: 15,
    search: '',
  },
};

const baseTestsSlice = createSlice({
  name: 'baseTests',
  initialState,
  reducers: {
    cancelRequests: () => {
      cancelToken?.cancel();
      cancelRequests();
    },
    cleanState: () => ({ ...initialState }),
    /**
     * indicate that a request is started
     */
    requestBaseTests: (state) => {
      state.loading = true;
      state.error = null;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    /**
     * receive a success response
     */
    receiveRequestSuccess: (state) => {
      state.lading = false;
    },
    /**
     * receive a success baseTests list response
     */
    receiveBaseTestsList: (state, action) => {
      state.loading = false;

      state.data = {
        ...state.data,
        baseTests: ConvertPlacementToProgressCheck(
          action.payload?.tests,
          action.payload?.hideProgressCheck
        ),
        total: action.payload?.total_items,
      };
    },
    clearBaseTestsList: (state) => {
      state.loading = false;
      state.data = {
        page: 1,
        perPage: 15,
      };
    },
    /**
     * receive an error response
     */
    receiveBaseTestsError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    changeBaseTestsPage: (state, action) => {
      state.data.page = action.payload;
    },
    changeBaseTestSearch: (state, action) => {
      state.data.search = action.payload.search;
      state.data.page = 1;
    },
  },
});

const Actions = baseTestsSlice.actions;

const Selectors = {
  fetchListData: (state) => state.baseTests,
  baseTestLoading: ({ baseTest: { loading } }) => ({ loading }),
};

const Async = {
  fetchBaseTestsList:
    ({ hideProgressCheck }) =>
    async (dispatch) => {
      ejectCancelInterceptor();
      cancelToken?.cancel();
      cancelToken = generateCancelToken();

      dispatch(Actions.requestBaseTests());

      try {
        const response = await request({
          cancelToken: cancelToken.token,
          method: 'GET',
          url: BASE_TESTS_URL,
          params: {
            sort: 'ASC',
            sort_by: 'name',
            show_all_tests: true,
          },
        });
        dispatch(
          Actions.receiveBaseTestsList({
            ...response.data.content,
            hideProgressCheck,
          })
        );
      } catch (e) {
        if (!isCancel(e)) {
          dispatch(Actions.receiveBaseTestsError(e.message));
        }
      }
    },

  getBaseTestById:
    ({ id, onSuccess, onError }) =>
    async () => {
      try {
        const response = await request({
          method: 'GET',
          url: `${BASE_TEST_BY_ID_URL}?id=${id}`,
        });

        onSuccess(response);
      } catch (e) {
        onError(e);
      }
    },
};

const { reducer } = baseTestsSlice;

export { reducer, Actions, Async, Selectors };
