import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { Can } from 'hoc/Ability';
import { Tooltip, ConfirmPopover } from 'modules/Core/Common';
import { Container, Button } from './styles';
import {
  EmailIcon,
  EditIcon,
  KeyIcon,
  DeleteIcon,
} from '@edusynch/edusynch-svg-icons';

const BUTTON_PREFIX = 'commons.buttons.actions-buttons';

const Actions = ({
  id,
  email,
  onCopyEmail,
  onEdit,
  onNewPassword,
  onDelete,
  subject,
  isDeleting,
}) => (
  <Container>
    {onCopyEmail && email && (
      <Tooltip
        placement="bottom"
        overlay={i18n.t(`${BUTTON_PREFIX}.copy-email`)}
        secondaryTooltip
        overlayInnerStyle={{
          backgroundColor: '#0075EA',
          borderRadius: '4px',
          padding: '8px 16px',
          fontSize: '14px',
        }}
      >
        <Button onClick={() => onCopyEmail(email)}>
          <EmailIcon />
        </Button>
      </Tooltip>
    )}

    <Can I="update" a={subject}>
      <Tooltip
        placement="bottom"
        overlay={i18n.t(`${BUTTON_PREFIX}.edit`)}
        secondaryTooltip
        overlayInnerStyle={{
          backgroundColor: '#0075EA',
          borderRadius: '4px',
          padding: '8px 16px',
          fontSize: '14px',
        }}
      >
        <Button onClick={() => onEdit(id)}>
          <EditIcon data-cy="edit-btn" />
        </Button>
      </Tooltip>
    </Can>

    {onNewPassword && (
      <Tooltip
        placement="bottom"
        overlay={i18n.t(`${BUTTON_PREFIX}.new-password`)}
        secondaryTooltip
        overlayInnerStyle={{
          backgroundColor: '#0075EA',
          borderRadius: '4px',
          padding: '8px 16px',
          fontSize: '14px',
        }}
      >
        <Button onClick={() => onNewPassword(id)}>
          <KeyIcon />
        </Button>
      </Tooltip>
    )}

    <Can I="destroy" a={subject}>
      <Tooltip
        placement="bottom"
        overlay={i18n.t(`${BUTTON_PREFIX}.delete`)}
        secondaryTooltip
        overlayInnerStyle={{
          backgroundColor: '#0075EA',
          borderRadius: '4px',
          padding: '8px 16px',
          fontSize: '14px',
        }}
      >
        <ConfirmPopover
          onConfirm={() => onDelete(id)}
          isLoading={isDeleting}
          subtitle="This action will delete this item."
        >
          <DeleteIcon data-cy="delete-btn" />
        </ConfirmPopover>
      </Tooltip>
    </Can>
  </Container>
);

Actions.propTypes = {
  id: PropTypes.number,
  onEdit: PropTypes.func,
  onNewPassword: PropTypes.func,
  onDelete: PropTypes.func,
  email: PropTypes.string,
  subject: PropTypes.string,
  onCopyEmail: PropTypes.func,
  isDeleting: PropTypes.bool,
};

export default Actions;
